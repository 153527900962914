/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app-launcher-overlay.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./app-launcher-overlay.component";
import * as i5 from "tiime-material";
var styles_AppLauncherOverlayComponent = [i0.styles];
var RenderType_AppLauncherOverlayComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppLauncherOverlayComponent, data: {} });
export { RenderType_AppLauncherOverlayComponent as RenderType_AppLauncherOverlayComponent };
export function View_AppLauncherOverlayComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.close() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["draggable", "false"]], [[8, "src", 4], [8, "alt", 0]], null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.appLauncherOverlayData.routerLink; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).target; var currVal_1 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = _co.appLauncherOverlayData.assetSrc; var currVal_4 = _co.appLauncherOverlayData.assetAlt; _ck(_v, 2, 0, currVal_3, currVal_4); }); }
export function View_AppLauncherOverlayComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-app-launcher-overlay", [], null, null, null, View_AppLauncherOverlayComponent_0, RenderType_AppLauncherOverlayComponent)), i1.ɵdid(1, 49152, null, 0, i4.AppLauncherOverlayComponent, [i5.TiimeOverlayRef, i5.TIIME_OVERLAY_DATA], null, null)], null, null); }
var AppLauncherOverlayComponentNgFactory = i1.ɵccf("app-app-launcher-overlay", i4.AppLauncherOverlayComponent, View_AppLauncherOverlayComponent_Host_0, {}, {}, []);
export { AppLauncherOverlayComponentNgFactory as AppLauncherOverlayComponentNgFactory };
