import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { VatType } from './models/vat-type';
import { ApiAlertError } from './decorators/api-alert-error';
import { VatSystem } from './models/vat_system';
import { ApeCode } from './models/ape_code';
import { BusinessUser } from './models/business-user';
import { IncomeCategory } from './models/income_category';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private apeCodesCache: Observable<ApeCode[]>;
  private incomeCategoriesCache: Observable<IncomeCategory[]>;
  private invoicingVatTypesCache: Observable<VatType[]>;
  private legalFormsCache: Observable<string[]>;
  private vatSystemsCache: Observable<VatSystem[]>;
  private vatTypesCache: Observable<VatType[]>;

  constructor(private http: HttpClient) {}

  @ApiAlertError()
  getApeCodes(): Observable<ApeCode[]> {
    if (!this.apeCodesCache) {
      const url = `api/v1/ape_codes`;

      this.apeCodesCache = this.http.get(url).pipe(
        map((apeCodesJson: any) =>
          apeCodesJson.map((apeCodeJson: any) => ApeCode.fromJson(apeCodeJson))
        ),
        shareReplay({ refCount: true, bufferSize: 1 })
      );
    }

    return this.apeCodesCache;
  }

  @ApiAlertError()
  getBusinessUser(): Observable<BusinessUser> {
    const url = 'api/v1/pro/business_users/me';

    return this.http
      .get(url)
      .pipe(
        map((businessUserJson: any) => BusinessUser.fromJson(businessUserJson))
      );
  }

  @ApiAlertError()
  getIncomeCategories(): Observable<IncomeCategory[]> {
    if (!this.incomeCategoriesCache) {
      const url = 'api/v1/income_categories';

      this.incomeCategoriesCache = this.http.get(url).pipe(
        map((incomeCategoriesJson: any) =>
          incomeCategoriesJson.map((incomeCategoryJson: any) =>
            IncomeCategory.fromJson(incomeCategoryJson)
          )
        ),
        shareReplay({ refCount: true, bufferSize: 1 })
      );
    }

    return this.incomeCategoriesCache;
  }

  @ApiAlertError()
  getInvoicingVatTypes(): Observable<VatType[]> {
    if (!this.invoicingVatTypesCache) {
      const url = `api/v1/pro/invoicing_config/vat_types`;

      this.invoicingVatTypesCache = this.http.get(url).pipe(
        map((vatTypesJson: any) =>
          vatTypesJson.map((vatTypeJson: any) => VatType.fromJson(vatTypeJson))
        ),
        shareReplay({ refCount: true, bufferSize: 1 })
      );
    }

    return this.invoicingVatTypesCache;
  }

  @ApiAlertError()
  getLegalForms(): Observable<string[]> {
    if (!this.legalFormsCache) {
      const url = `api/v1/legal_forms`;

      this.legalFormsCache = this.http.get<string[]>(url).pipe(
        map((legalForms: string[]) =>
          legalForms.filter((legalForm: string) => legalForm !== 'UserApp')
        ),
        shareReplay({ refCount: true, bufferSize: 1 })
      );
    }

    return this.legalFormsCache;
  }

  @ApiAlertError()
  getVatSystems(): Observable<VatSystem[]> {
    if (!this.vatSystemsCache) {
      const url = `api/v1/vat_systems`;

      this.vatSystemsCache = this.http.get(url).pipe(
        map((vatSystemsJson: any) =>
          vatSystemsJson.map((vatSystemJson: any) =>
            VatSystem.fromJson(vatSystemJson)
          )
        ),
        shareReplay({ refCount: true, bufferSize: 1 })
      );
    }

    return this.vatSystemsCache;
  }

  @ApiAlertError()
  getVatTypes(): Observable<VatType[]> {
    if (!this.vatTypesCache) {
      const url = `api/v1/pro/vat_types`;

      this.vatTypesCache = this.http.get(url).pipe(
        map((vatTypesJson: any) =>
          vatTypesJson.map((vatTypeJson: any) => VatType.fromJson(vatTypeJson))
        ),
        shareReplay({ refCount: true, bufferSize: 1 })
      );
    }

    return this.vatTypesCache;
  }
}
