import { Component, Inject, OnInit } from '@angular/core';
import { ParamMap } from '@angular/router';
import { EMPTY, from, Observable } from 'rxjs';
import { catchError, filter, map, tap } from 'rxjs/operators';
import { TiimeAuthService } from 'tiime-auth';
import { SnackbarConfig, TiimeSnackbarService } from 'tiime-material';
import { MarkFormAsTouchedIfInvalid } from 'tiime-utils';

import { SignInForm } from './signin-form';
import { AUTH } from '../../constants/auth.constants';
import { WINDOW } from '../../tokens/window.token';
import { POST_SIGN_IN_TARGET_PARAM_NAME } from '../auth-callback/auth-callback.component';
import { QUERY_PARAMS_MAP } from '../../tokens/query-params-map.token';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['../auth-base.scss']
})
export class SigninComponent implements OnInit {
  readonly signInForm: SignInForm = new SignInForm();
  readonly tiimePulseUrl = AUTH.tiimePulseWebsiteUrl;

  private postSignInTargetPath: string;

  constructor(
    private authService: TiimeAuthService,
    @Inject(QUERY_PARAMS_MAP) private queryParamsMap$: Observable<ParamMap>,
    private snackbar: TiimeSnackbarService,
    @Inject(WINDOW) private windowRef: Window
  ) {}

  ngOnInit() {
    this.initPostSignInTargetPath();
  }

  @MarkFormAsTouchedIfInvalid('signInForm')
  submitSignInForm(): void {
    this.login();
  }

  private clearPostSignInTargetQueryParamFromURL(): void {
    const queryParams = new URLSearchParams(this.windowRef.location.search);
    queryParams.delete(POST_SIGN_IN_TARGET_PARAM_NAME);
    const queryParamsString = queryParams.toString();
    const urlSearch = queryParamsString ? `?${queryParamsString}` : '';
    this.windowRef.history.replaceState(
      {},
      '',
      `${this.windowRef.location.pathname}${urlSearch}`
    );
  }

  private getLoginObservable(): Observable<any> {
    const email = this.signInForm.email.value;
    const password = this.signInForm.password.value;
    const promiseToLogin = this.postSignInTargetPath
      ? this.authService.loginAndRedirect(
          email,
          password,
          `${this.windowRef.location.origin}/auth-callback?${POST_SIGN_IN_TARGET_PARAM_NAME}=${this.postSignInTargetPath}`
        )
      : this.authService.login(email, password);
    return from(promiseToLogin);
  }

  private initPostSignInTargetPath(): void {
    this.queryParamsMap$
      .pipe(
        map((queryParams: ParamMap) =>
          queryParams.get(POST_SIGN_IN_TARGET_PARAM_NAME)
        ),
        filter((postSignInTargetPath: string) => !!postSignInTargetPath),
        tap(
          (postSignInTargetPath: string) =>
            (this.postSignInTargetPath = postSignInTargetPath)
        ),
        tap(() => this.clearPostSignInTargetQueryParamFromURL())
      )
      .subscribe();
  }

  private login(): void {
    this.getLoginObservable()
      .pipe(
        catchError(() => {
          this.loginError();
          return EMPTY;
        })
      )
      .subscribe();
  }

  private loginError(): void {
    this.snackbar.open(
      'Identifiant ou mot de passe invalide',
      SnackbarConfig.error
    );
  }
}
