<div class="tiime-filter-search" [class.disabled]="disabled">
  <div
    class="search-like-wrapper"
    [class.display-search-icon]="
      filters?.length | map: mapToIsEmpty:input.value
    "
    matAutocompleteOrigin
    #origin="matAutocompleteOrigin"
  >
    <mat-chip-list #chipList>
      <mat-basic-chip
        *ngFor="let filter of filters; let i = index; trackBy: trackByIndex"
        [selectable]="false"
        removable
        (removed)="removeFilter(i, filter)"
        [class.error]="!filter.control.valid"
      >
        {{ filter.inputValue }}
        <span matChipRemove data-icon="&#xe009;"></span>
      </mat-basic-chip>
      <input
        #input
        [placeholder]="label"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        (matChipInputTokenEnd)="addFilter($event)"
        [class.init-state]="filters?.length | map: mapToIsEmpty:input.value"
        [formControl]="filterControl"
        [matAutocomplete]="filterAuto"
        [matAutocompleteConnectedTo]="origin"
      />
    </mat-chip-list>
    <mat-autocomplete
      class="filter-autocomplete-panel"
      #filterAuto="matAutocomplete"
      (optionSelected)="autocompleteOptionSelected($event)"
    >
      <mat-option
        *ngFor="
          let option of filteredFilterOptions | async;
          trackBy: trackByIndex
        "
        [value]="option"
      >
        <div class="option-first-line">
          <span class="option-filter">{{ option.filter }}</span>
          <span class="option-placeholder">{{ option.placeholder }}</span>
        </div>
        <span class="option-description">{{ option.description }}</span>
      </mat-option>
    </mat-autocomplete>
    <div
      *ngIf="filters?.length | map: mapToHasFilters"
      class="clear-icn"
      (click)="clearFilters()"
    >
      <mat-icon inline="true" fontIcon="icon-ic-remove"></mat-icon>
    </div>
  </div>
</div>
