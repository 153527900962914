import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { TiimeSnackbarService, SnackbarConfig } from 'tiime-material';

import { isBusinessAgentSelector } from '../store/business-user/business-user-selector';

@Injectable({
  providedIn: 'root'
})
export class BusinessAgentGuard implements CanActivate, CanLoad {
  constructor(
    private router: Router,
    private snackbar: TiimeSnackbarService,
    private store: Store<any>
  ) {}

  canActivate(): Observable<boolean> {
    return this.isAgent();
  }

  canLoad(): Observable<boolean> {
    return this.isAgent();
  }

  private isAgent(): Observable<boolean> {
    return this.store.pipe(
      select(isBusinessAgentSelector),
      take(1),
      tap((isBusinessAgent: boolean) => {
        if (!isBusinessAgent) {
          this.snackbar.open(`Accès refusé`, SnackbarConfig.error);
          this.router.navigate(['/signin']);
        }
      })
    );
  }
}
