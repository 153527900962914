import { Action } from '@ngrx/store';

import { BusinessUser } from '../../models/business-user';

export const UPDATE = '[BusinessUser] Update';

export class UpdateAction implements Action {
  readonly type = UPDATE;

  constructor(public payload: BusinessUser) {}
}

export type Actions = UpdateAction;
