import { Component, Inject, ChangeDetectionStrategy } from '@angular/core';
import { TIIME_OVERLAY_DATA, TiimeOverlayRef } from 'tiime-material';

export interface AppLauncherOverlayData {
  routerLink: string;
  assetSrc: string;
  assetAlt: string;
}

@Component({
  selector: 'app-app-launcher-overlay',
  templateUrl: './app-launcher-overlay.component.html',
  styleUrls: ['./app-launcher-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppLauncherOverlayComponent {
  constructor(
    public overlayRef: TiimeOverlayRef,
    @Inject(TIIME_OVERLAY_DATA)
    public appLauncherOverlayData: AppLauncherOverlayData
  ) {}

  close(): void {
    this.overlayRef.close();
  }
}
