import { HttpErrorResponse } from '@angular/common/http';
import * as Sentry from '@sentry/browser';
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
export class SentryService {
    constructor() { }
    captureException(error) {
        if (environment.SENTRY_DNS) {
            Sentry.captureException(error.originalError || error);
        }
        else {
            console.error(error);
        }
    }
    cleanUser() {
        if (environment.SENTRY_DNS) {
            Sentry.configureScope(scope => scope.setUser(null));
        }
    }
    init() {
        if (environment.SENTRY_DNS) {
            Sentry.init({
                dsn: environment.SENTRY_DNS,
                environment: environment.ENV_NAME,
                release: environment.VERSION,
                beforeSend(event, hint) {
                    const error = hint.originalException;
                    if (error && error instanceof HttpErrorResponse) {
                        return null;
                    }
                    return event;
                }
            });
        }
    }
    setUserFromBusinessUser(businessUser) {
        if (environment.SENTRY_DNS) {
            Sentry.configureScope(scope => scope.setUser({
                id: String(businessUser.id),
                username: businessUser.fullName,
                email: businessUser.email,
                phone: businessUser.phone
            }));
        }
    }
}
SentryService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SentryService_Factory() { return new SentryService(); }, token: SentryService, providedIn: "root" });
