import { UserBusinessUnitRole } from './user-business-unit-role';
import { CompanyListItem } from './company-list-item';
import { UserBase } from './user-base';

export class User extends UserBase {
  constructor(
    id?: number,
    firstName?: string,
    lastName?: string,
    email?: string,
    public phone?: string,
    public companies?: CompanyListItem[],
    public passwordUpdatedAt?: string,
    public businessUnitsRole?: UserBusinessUnitRole[]
  ) {
    super(id, firstName, lastName, email);
  }

  static fromJson(json: any): User {
    return new User(
      json.id,
      json.firstname,
      json.lastname,
      json.email,
      json.phone,
      json.companies
        ? json.companies.map((companyJson: object) =>
            CompanyListItem.fromJson(companyJson)
          )
        : [],
      json.password_updated_at,
      json.business_unit_users
        ? json.business_unit_users.map((businessUserBusinessUnitRole: any) =>
            UserBusinessUnitRole.fromJson(businessUserBusinessUnitRole)
          )
        : []
    );
  }

  static toJson(user: User): any {
    return {
      id: user.id,
      firstname: user.firstName,
      lastname: user.lastName,
      email: user.email,
      phone: user.phone || null
    };
  }
}
