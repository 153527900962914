import { NgModule } from '@angular/core';
import { JwtModule } from '@auth0/angular-jwt';
import { TiimeAuthModule } from 'tiime-auth';

import { SigninComponent } from './signin/signin.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { AuthCallbackComponent } from './auth-callback/auth-callback.component';
import { environment } from '../../../environments/environment';
import { SharedModule } from '../../shared/shared.module';
import { AuthLayoutComponent } from './auth-layout/auth-layout.component';

export function tokenGetter() {
  return localStorage.getItem('access_token');
}

@NgModule({
  imports: [
    SharedModule,
    TiimeAuthModule.forRoot({
      config: {
        AUTH0_AUDIENCE: environment.AUTH0_AUDIENCE,
        AUTH0_CLIENT_ID: environment.AUTH0_CLIENT_ID,
        AUTH0_DOMAIN: environment.AUTH0_DOMAIN,
        AUTH0_REALM: environment.AUTH0_REALM,
        AUTH0_WHITE_DOMAINS: environment.AUTH0_WHITE_DOMAINS
      }
    }),
    JwtModule.forRoot({
      config: {
        tokenGetter,
        whitelistedDomains: environment.AUTH0_WHITE_DOMAINS
      }
    })
  ],
  declarations: [
    AuthCallbackComponent,
    SigninComponent,
    ChangePasswordComponent,
    AuthLayoutComponent
  ]
})
export class AuthModule {}
