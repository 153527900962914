<div class="overlay-container" cdkScrollable>
  <img
    role="button"
    class="close-overlay"
    src="dist/tiime-material/assets/close.svg"
    alt="close panel"
    (click)="emitClosePanel()"
  />
  <ng-content></ng-content>
</div>
