<div class="t2-mat-progress-bar-loader">
  <mat-progress-bar
    [mode]="mode"
    [color]="color"
    [value]="value"
  ></mat-progress-bar>
  <div class="t2-mat-progress-bar-loader-text">
    <ng-content></ng-content>
  </div>
</div>
