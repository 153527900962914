/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./version-switch-confirmation-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "@angular/material/dialog";
import * as i5 from "../../../../../../../dist/tiime-material/tiime-material.ngfactory";
import * as i6 from "tiime-material";
import * as i7 from "./version-switch-confirmation-dialog.component";
var styles_VersionSwitchConfirmationDialogComponent = [i0.styles];
var RenderType_VersionSwitchConfirmationDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_VersionSwitchConfirmationDialogComponent, data: {} });
export { RenderType_VersionSwitchConfirmationDialogComponent as RenderType_VersionSwitchConfirmationDialogComponent };
export function View_VersionSwitchConfirmationDialogComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "close-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "mat-icon", [["class", "mat-icon notranslate"], ["fontIcon", "icon-ic-remove"], ["inline", "true"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(2, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], { inline: [0, "inline"], fontIcon: [1, "fontIcon"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 6, "mat-dialog-content", [["class", "dialog-content mat-dialog-content"], ["mat-dialog-content", ""]], null, null, null, null, null)), i1.ɵdid(4, 16384, null, 0, i4.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [["alt", "Pulse license logo"], ["src", "assets/illu_switch_version.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), (_l()(), i1.ɵeld(10, 0, null, null, 7, "mat-dialog-actions", [["class", "dialog-actions mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(11, 16384, null, 0, i4.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(12, 0, null, null, 2, "t2-mat-button", [["accent", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_ButtonComponent_0, i5.RenderType_ButtonComponent)), i1.ɵdid(13, 49152, null, 0, i6.ButtonComponent, [i1.ElementRef], null, null), (_l()(), i1.ɵted(-1, 0, ["Annuler"])), (_l()(), i1.ɵeld(15, 0, null, null, 2, "t2-mat-button", [["accent", ""], ["raised", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_ButtonComponent_0, i5.RenderType_ButtonComponent)), i1.ɵdid(16, 49152, null, 0, i6.ButtonComponent, [i1.ElementRef], { raised: [0, "raised"] }, null), (_l()(), i1.ɵted(-1, 0, ["Valider"]))], function (_ck, _v) { var currVal_2 = "true"; var currVal_3 = "icon-ic-remove"; _ck(_v, 2, 0, currVal_2, currVal_3); var currVal_6 = ""; _ck(_v, 16, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 2).inline; var currVal_1 = (((i1.ɵnov(_v, 2).color !== "primary") && (i1.ɵnov(_v, 2).color !== "accent")) && (i1.ɵnov(_v, 2).color !== "warn")); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_4 = _co.data.title; _ck(_v, 7, 0, currVal_4); var currVal_5 = _co.data.message; _ck(_v, 9, 0, currVal_5); }); }
export function View_VersionSwitchConfirmationDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-version-switch-confirmation-dialog", [], null, null, null, View_VersionSwitchConfirmationDialogComponent_0, RenderType_VersionSwitchConfirmationDialogComponent)), i1.ɵdid(1, 49152, null, 0, i7.VersionSwitchConfirmationDialogComponent, [i4.MAT_DIALOG_DATA, i4.MatDialogRef], null, null)], null, null); }
var VersionSwitchConfirmationDialogComponentNgFactory = i1.ɵccf("app-version-switch-confirmation-dialog", i7.VersionSwitchConfirmationDialogComponent, View_VersionSwitchConfirmationDialogComponent_Host_0, {}, {}, []);
export { VersionSwitchConfirmationDialogComponentNgFactory as VersionSwitchConfirmationDialogComponentNgFactory };
