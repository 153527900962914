export class CompanyReminder {
  constructor(public type?: string, public remindedAt?: string) {}

  static fromJson(json: any): CompanyReminder {
    return new CompanyReminder(json.type, json.reminded_at);
  }

  static toJson(companyReminder: CompanyReminder): object {
    return {
      type: companyReminder.type,
      reminded_at: companyReminder.remindedAt
    };
  }
}
