<div class="mat-sticky-table-container">
  <ng-content select="[t2MatStickyTablePreview]"></ng-content>
  <div class="mat-sticky-table" [class.with-preview]="stickyTablePreview">
    <ng-content select="[t2MatStickyTableHeader]"></ng-content>
    <ng-content select="[t2MatStickyTableContent]"></ng-content>
    <ng-content select="[t2MatStickyTableFooter]"></ng-content>
    <t2-mat-sticky-table-content-placeholder
      *ngIf="tableState === TableState.contentPlaceholder"
    ></t2-mat-sticky-table-content-placeholder>
    <t2-mat-sticky-table-no-results
      *ngIf="tableState === TableState.noResult"
    ></t2-mat-sticky-table-no-results>
  </div>
  <ng-content
    select="[t2MatStickyTableOnboard]"
    *ngIf="tableState === TableState.onboard"
  ></ng-content>
</div>
