import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { filter, catchError, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Company } from './models/company';
import { companySelector } from '../pro/company/company-core/store/company/company-selector';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  private companyId: number;

  constructor(private store: Store<any>, private router: Router) {
    this.observeSelectedCompany();
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.url.startsWith('api/')) {
      let url = this.replaceApi(request.url);
      if (url.includes('{companyId}')) {
        url = this.replaceCompanyId(url);
      }

      request = request.clone({
        url,
        setHeaders: {
          'tiime-app': 'tiime-pro',
          'tiime-app-version': environment.VERSION
        }
      });
    }

    return next.handle(request).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          this.router.navigate(['signin']);
        }
        return throwError(error);
      })
    );
  }

  private observeSelectedCompany(): void {
    this.store
      .pipe(
        select(companySelector),
        filter((company: Company) => !!company),
        tap((company: Company) => (this.companyId = company.id))
      )
      .subscribe();
  }

  private replaceApi(url: string): string {
    return `${environment.API_URL}/${url.replace('api/', '')}`;
  }

  private replaceCompanyId(url: string): string {
    return url.replace('{companyId}', String(this.companyId));
  }
}
