import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { TiimeAuthService } from 'tiime-auth';
import { MarkFormAsTouchedIfInvalid } from 'tiime-utils';

import { ChangePasswordForm } from './change-password-form';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['../auth-base.scss', './change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  changePasswordForm: ChangePasswordForm;
  isMailSent = false;

  constructor(
    private authService: TiimeAuthService,
    private cdr: ChangeDetectorRef,
    private router: Router
  ) {}

  ngOnInit() {
    this.initChangePasswordForm();
  }

  @MarkFormAsTouchedIfInvalid('changePasswordForm')
  submitChangePasswordForm(): void {
    this.changePassword();
  }

  navigateToSignin(): void {
    this.router.navigateByUrl('/signin');
  }

  private initChangePasswordForm(): void {
    this.changePasswordForm = new ChangePasswordForm();
  }

  private changePassword(): void {
    this.authService
      .changePassword(this.changePasswordForm.email.value)
      .then(() => this.changePasswordSuccess());
  }

  private changePasswordSuccess(): void {
    this.isMailSent = true;
    this.cdr.markForCheck();
  }
}
