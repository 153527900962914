<app-auth-layout>
  <form
    class="auth-form"
    [formGroup]="signInForm"
    (ngSubmit)="submitSignInForm()"
  >
    <div class="auth-form-main-container">
      <div class="auth-form-header">
        <div class="auth-form-header-illustration"></div>
        <div class="auth-form-header-separator"></div>
        <span class="auth-form-header-title">Connectez-vous</span>
        <span class="auth-form-header-subtitle"
          >pour accéder à votre espace cabinet partenaire</span
        >
      </div>
      <div class="auth-form-content">
        <div class="input-container">
          <input
            type="text"
            placeholder="Adresse email"
            formControlName="email"
          />
          <label
            class="input-error-label"
            *ngIf="signInForm.emailErrorMessage"
            >{{ signInForm.emailErrorMessage }}</label
          >
        </div>
        <div class="input-container">
          <input
            type="password"
            placeholder="Mot de passe"
            formControlName="password"
          />
          <label
            class="input-error-label"
            *ngIf="signInForm.passwordErrorMessage"
            >{{ signInForm.passwordErrorMessage }}</label
          >
        </div>
        <t2-mat-button action raised accent>Je me connecte</t2-mat-button>
      </div>
      <div class="auth-form-link">
        <a [routerLink]="['/changepassword']">Mot de passe oublié ?</a>
      </div>
    </div>
    <div class="auth-form-link bottom">
      Vous êtes expert-comptable ?
      <a target="_blank" [href]="tiimePulseUrl">En savoir plus</a>
    </div>
  </form>
</app-auth-layout>
