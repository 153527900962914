export class Partner {
  constructor(public id?: number, public name?: string) {}

  static fromJson(json: any): Partner {
    return new Partner(json.id, json.name);
  }

  static toJson(partner: Partner): any {
    return {
      id: partner.id,
      firstname: partner.name
    };
  }
}
