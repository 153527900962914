export class VatSystem {
  constructor(
    public id?: number,
    public code?: string,
    public label?: string,
    public type?: string
  ) {}

  static fromJson(json: any): VatSystem {
    return new VatSystem(json.id, json.code, json.label, json.type);
  }

  static toJson(vatSystem: VatSystem): any {
    return {
      id: vatSystem.id,
      code: vatSystem.code,
      label: vatSystem.label,
      type: vatSystem.type
    };
  }
}
