import { BusinessUnit } from './business-unit';
import { UserRole } from './user-role';

export class UserBusinessUnitRole {
  constructor(
    public businessUnit?: BusinessUnit,
    public role?: UserRole,
    public isAdmin?: boolean
  ) {}

  static fromJson(json: any): UserBusinessUnitRole {
    return new UserBusinessUnitRole(
      json.business_unit ? BusinessUnit.fromJson(json.business_unit) : null,
      json.role ? UserRole.fromJson(json.role) : null,
      json.is_admin
    );
  }

  static toJson(userBusinessUnitRole: UserBusinessUnitRole): any {
    return {
      business_unit: BusinessUnit.toJson(userBusinessUnitRole.businessUnit),
      role: UserRole.toJson(userBusinessUnitRole.role),
      is_admin: userBusinessUnitRole.isAdmin
    };
  }
}
