import { createSelector } from '@ngrx/store';

import { Role } from '../../enums/role';
import { BusinessUser } from '../../models/business-user';

export const businessUserSelector = (state: any): BusinessUser => {
  return state && state.businessUser ? state.businessUser : null;
};
export const isBusinessAgentSelector = createSelector(
  businessUserSelector,
  (businessUser: BusinessUser): boolean =>
    businessUser.roles.includes(Role.BUSINESS_AGENT)
);
export const isBusinessAdminSelector = createSelector(
  businessUserSelector,
  (businessUser: BusinessUser): boolean =>
    businessUser.roles.includes(Role.BUSINESS_ADMIN)
);
export const businessUserRolesSelector = createSelector(
  businessUserSelector,
  (businessUser: BusinessUser): Role[] => businessUser.roles
);
