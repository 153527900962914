<app-auth-layout>
  <form
    class="auth-form"
    [formGroup]="changePasswordForm"
    (ngSubmit)="submitChangePasswordForm()"
  >
    <div class="auth-form-header">
      <div class="auth-form-header-illustration"></div>
      <div class="auth-form-header-separator"></div>
      <span class="auth-form-header-title">Modifier mon mot de passe</span>
    </div>
    <div *ngIf="isMailSent; then mailSent; else form"></div>
    <ng-template #form>
      <div class="auth-form-content">
        <span class="info">Je saisis mon adresse mail</span>
        <div class="input-container">
          <input
            type="text"
            placeholder="Adresse email"
            formControlName="email"
          />
          <label
            class="input-error-label"
            *ngIf="changePasswordForm.emailErrorMessage"
            >{{ changePasswordForm.emailErrorMessage }}</label
          >
        </div>
        <t2-mat-button raised accent>Envoyer l'email</t2-mat-button>
      </div>
    </ng-template>
    <ng-template #mailSent>
      <div class="auth-form-content">
        <span class="info"
          >Un email vient d'être envoyé pour réinitialiser le mot de passe</span
        >
        <t2-mat-button raised accent (click)="navigateToSignin()"
          >Ré-essayer de me connecter</t2-mat-button
        >
      </div>
    </ng-template>
    <div class="auth-form-link bottom">
      Déjà un compte ?
      <a [routerLink]="['/signin']">Je me connecte sur Tiime Pulse !</a>
    </div>
  </form>
</app-auth-layout>
