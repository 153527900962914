<div class="auth-container">
  <div class="auth-left-container"><ng-content></ng-content></div>
  <div class="auth-right-container">
    <div class="auth-right-container-content">
      <img
        class="content-img"
        src="assets/illu_tiime_pulse_parrainage.png"
        alt="illu tiime pulse parrainage"
      />
      <span class="content-title">
        Parlez de Tiime, parrainez un confrère !
      </span>
      <span class="content-description">
        C’est encore vous qui en parlez le mieux ! Nous serions ravis de leur
        faire découvrir nos solutions grâce à votre mise en relation.
        <br />
        Vous êtes Expert comptable ? Gagnez 1 mois gratuit pour vous et votre
        filleul. Collaborateur ? Gagnez un cadeau.
      </span>
      <div class="content-action">
        <a
          class="referral-action"
          target="_blank"
          href="https://tiime.typeform.com/to/bys72mrI"
        >
          Je parraine un confrère
        </a>
      </div>
    </div>
  </div>
</div>
