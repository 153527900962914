/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./confirm-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "../../../../../../../dist/tiime-material/tiime-material.ngfactory";
import * as i4 from "tiime-material";
import * as i5 from "./confirm-dialog.component";
var styles_ConfirmDialogComponent = [i0.styles];
var RenderType_ConfirmDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConfirmDialogComponent, data: {} });
export { RenderType_ConfirmDialogComponent as RenderType_ConfirmDialogComponent };
export function View_ConfirmDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "mat-dialog-content", [["class", "dialog-content mat-dialog-content"], ["mat-dialog-content", ""]], null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["class", "message"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 7, "mat-dialog-actions", [["class", "dialog-actions mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(7, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(8, 0, null, null, 2, "t2-mat-button", [["accent", ""], ["raised", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_ButtonComponent_0, i3.RenderType_ButtonComponent)), i1.ɵdid(9, 49152, null, 0, i4.ButtonComponent, [i1.ElementRef], { raised: [0, "raised"] }, null), (_l()(), i1.ɵted(10, 0, ["", ""])), (_l()(), i1.ɵeld(11, 0, null, null, 2, "t2-mat-button", [["accent", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_ButtonComponent_0, i3.RenderType_ButtonComponent)), i1.ɵdid(12, 49152, null, 0, i4.ButtonComponent, [i1.ElementRef], null, null), (_l()(), i1.ɵted(13, 0, ["", ""]))], function (_ck, _v) { var currVal_2 = ""; _ck(_v, 9, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.confirmTitle; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.data.confirmMessage; _ck(_v, 5, 0, currVal_1); var currVal_3 = _co.data.confirmAction; _ck(_v, 10, 0, currVal_3); var currVal_4 = _co.data.cancelAction; _ck(_v, 13, 0, currVal_4); }); }
export function View_ConfirmDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-confirm-dialog", [], null, null, null, View_ConfirmDialogComponent_0, RenderType_ConfirmDialogComponent)), i1.ɵdid(1, 49152, null, 0, i5.ConfirmDialogComponent, [i2.MAT_DIALOG_DATA, i2.MatDialogRef], null, null)], null, null); }
var ConfirmDialogComponentNgFactory = i1.ɵccf("app-confirm-dialog", i5.ConfirmDialogComponent, View_ConfirmDialogComponent_Host_0, {}, {}, []);
export { ConfirmDialogComponentNgFactory as ConfirmDialogComponentNgFactory };
