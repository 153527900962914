import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { take, tap } from 'rxjs/operators';
import { TiimeSnackbarService, SnackbarConfig } from 'tiime-material';
import { isBusinessAgentSelector } from '../store/business-user/business-user-selector';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "tiime-material";
import * as i3 from "@ngrx/store";
export class BusinessAgentGuard {
    constructor(router, snackbar, store) {
        this.router = router;
        this.snackbar = snackbar;
        this.store = store;
    }
    canActivate() {
        return this.isAgent();
    }
    canLoad() {
        return this.isAgent();
    }
    isAgent() {
        return this.store.pipe(select(isBusinessAgentSelector), take(1), tap((isBusinessAgent) => {
            if (!isBusinessAgent) {
                this.snackbar.open(`Accès refusé`, SnackbarConfig.error);
                this.router.navigate(['/signin']);
            }
        }));
    }
}
BusinessAgentGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BusinessAgentGuard_Factory() { return new BusinessAgentGuard(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.TiimeSnackbarService), i0.ɵɵinject(i3.Store)); }, token: BusinessAgentGuard, providedIn: "root" });
