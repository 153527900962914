import { Injectable } from '@angular/core';
import { CanActivate, CanLoad } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { map, take, switchMap, catchError, tap } from 'rxjs/operators';

import * as BusinessUserActions from '../store/business-user/business-user-actions';
import { ApiService } from '../api.service';
import { BusinessUser } from '../models/business-user';
import { businessUserSelector } from '../store/business-user/business-user-selector';

@Injectable({
  providedIn: 'root'
})
export class BusinessUserGuard implements CanActivate, CanLoad {
  constructor(private store: Store<any>, private apiService: ApiService) {}

  canActivate(): Observable<boolean> {
    return this.hasBusinessUser();
  }

  canLoad(): Observable<boolean> {
    return this.hasBusinessUser();
  }

  private hasBusinessUserInStore(): Observable<boolean> {
    return this.store.pipe(
      select(businessUserSelector),
      map((businessUser: BusinessUser) => !!businessUser),
      take(1)
    );
  }

  private hasBusinessUserInApi(): Observable<boolean> {
    return this.apiService.getBusinessUser().pipe(
      tap((businessUser: BusinessUser) =>
        this.store.dispatch(new BusinessUserActions.UpdateAction(businessUser))
      ),
      catchError(() => of(false)),
      switchMap(() => this.hasBusinessUserInStore())
    );
  }

  private hasBusinessUser(): Observable<boolean> {
    return this.hasBusinessUserInStore().pipe(
      switchMap((inStore: boolean) =>
        inStore ? of(inStore) : this.hasBusinessUserInApi()
      )
    );
  }
}
