import { Routes } from '@angular/router';
import { AuthCallbackComponent } from './core/auth/auth-callback/auth-callback.component';
import { SigninComponent } from './core/auth/signin/signin.component';
import { ChangePasswordComponent } from './core/auth/change-password/change-password.component';
import { BusinessUserGuard } from './core/guards/business-user.guard';
import { NotFoundComponent } from './core/not-found/not-found.component';
import { UnauthorizedComponent } from './core/unauthorized/unauthorized.component';
import { BusinessAdminGuard } from './core/guards/business-admin.guard';
import { AuthGuard } from './core/guards/auth.guard';
import { BusinessAgentGuard } from './core/guards/business-agent.guard';
const ɵ0 = () => import("./pro/pro.module.ngfactory").then(m => m.ProModuleNgFactory), ɵ1 = () => import("./admin/admin.module.ngfactory").then(m => m.AdminModuleNgFactory);
const routes = [
    { path: '', redirectTo: 'pro', pathMatch: 'full' },
    { path: 'signin', component: SigninComponent },
    { path: 'changepassword', component: ChangePasswordComponent },
    { path: 'auth-callback', component: AuthCallbackComponent },
    {
        path: 'pro',
        loadChildren: ɵ0,
        canLoad: [AuthGuard, BusinessUserGuard, BusinessAgentGuard]
    },
    {
        path: 'admin',
        loadChildren: ɵ1,
        canLoad: [AuthGuard, BusinessUserGuard, BusinessAdminGuard]
    },
    { path: 'unauthorized', component: UnauthorizedComponent },
    { path: 'not-found', component: NotFoundComponent },
    { path: '**', component: NotFoundComponent }
];
export class AppRoutingModule {
}
export { ɵ0, ɵ1 };
