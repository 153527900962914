export class ApeCode {
  constructor(
    public id?: number,
    public code?: string,
    public label?: string
  ) {}

  static fromJson(json: any): ApeCode {
    return new ApeCode(json.id, json.code, json.label);
  }

  static toJson(apeCode: ApeCode): any {
    return {
      id: apeCode.id,
      code: apeCode.code,
      label: apeCode.label
    };
  }
}
