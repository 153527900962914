import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { take, tap } from 'rxjs/operators';
import { TiimeSnackbarService, SnackbarConfig } from 'tiime-material';
import { isBusinessAdminSelector } from '../store/business-user/business-user-selector';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "tiime-material";
import * as i3 from "@ngrx/store";
export class BusinessAdminGuard {
    constructor(router, snackbar, store) {
        this.router = router;
        this.snackbar = snackbar;
        this.store = store;
    }
    canActivate() {
        return this.isAdmin();
    }
    canLoad() {
        return this.isAdmin();
    }
    isAdmin() {
        return this.store.pipe(select(isBusinessAdminSelector), take(1), tap((isBusinessAdmin) => {
            if (!isBusinessAdmin) {
                this.snackbar.open(`Accès refusé`, SnackbarConfig.error);
                this.router.navigate(['/signin']);
            }
        }));
    }
}
BusinessAdminGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BusinessAdminGuard_Factory() { return new BusinessAdminGuard(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.TiimeSnackbarService), i0.ɵɵinject(i3.Store)); }, token: BusinessAdminGuard, providedIn: "root" });
