import { Component, Inject, ChangeDetectionStrategy } from '@angular/core';
import {
  TIIME_OVERLAY_DATA,
  SnackbarConfig,
  TiimeSnackbarService
} from 'tiime-material';
import { TiimeAuthService } from 'tiime-auth';

import { BusinessUser } from '../../../core/models/business-user';
import { SentryService } from '../../../core/sentry/sentry.service';

@Component({
  selector: 'app-business-user-account-menu-overlay',
  templateUrl: './business-user-account-menu-overlay.component.html',
  styleUrls: ['./business-user-account-menu-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BusinessUserAccountMenuOverlayComponent {
  constructor(
    private authService: TiimeAuthService,
    private sentryService: SentryService,
    private snackbar: TiimeSnackbarService,
    @Inject(TIIME_OVERLAY_DATA) public businessUser: BusinessUser
  ) {}

  changePassword(): void {
    this.authService
      .userInfo()
      .then(userInfos => this.authService.changePassword(userInfos.email))
      .then(
        () =>
          this.snackbar.open(
            'Un email vient de vous être envoyé pour modifier votre mot de passe',
            SnackbarConfig.success
          ),
        () =>
          this.snackbar.open(
            `Erreur lors de l'envoi de l'email de demande de modification du mot de passe`,
            SnackbarConfig.error
          )
      );
  }

  logout(): void {
    this.authService.logout();
    this.sentryService.cleanUser();
  }
}
