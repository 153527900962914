<div class="t2-mat-autocomplete-input-wrapper">
  <div class="input-icon">
    <mat-icon
      [class.focus]="inputFocused"
      fontIcon="icon-ic-search"
      (click)="focusInput()"
    ></mat-icon>
  </div>
  <ng-content></ng-content>
  <div
    class="clear-input-icon"
    (click)="emitClearSeach()"
    *ngIf="displayClearIcon"
  >
    <mat-icon inline="true" fontIcon="icon-ic-remove"></mat-icon>
  </div>
</div>
