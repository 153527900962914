export class IncomeCategory {
  constructor(
    public id?: number,
    public name?: string
  ) {}

  static fromJson(json: any): IncomeCategory {
    return new IncomeCategory(json.id, json.name);
  }

  static toJson(incomeCategory: IncomeCategory): any {
    return {
      id: incomeCategory.id,
      name: incomeCategory.name
    };
  }
}
