import { Partner } from './partner';

export class BusinessUnit {
  constructor(
    public id?: number,
    public name?: string,
    public companiesNumber?: number,
    public internal?: boolean,
    public cfonbRestitutionEmail?: string,
    public cfonbImportEmail?: string,
    public ebicsCardCompatible?: boolean,
    public settingsNeeded?: boolean,
    public brand?: string,
    public managerFirstName?: string,
    public managerLastName?: string,
    public street?: string,
    public postalCode?: string,
    public city?: string,
    public legalName?: string,
    public siret?: string,
    public rcsCity?: string,
    public ebicsContactEmail?: string,
    public expertEnabled?: boolean,
    public partner?: Partner
  ) {}

  static fromJson(json: any): BusinessUnit {
    return new BusinessUnit(
      json.id,
      json.name,
      json.companies_count,
      json.internal,
      json.cfonb_restitution_email,
      json.cfonb_import_email,
      json.ebics_card_compatible,
      json.settings_needed,
      json.brand,
      json.manager_first_name,
      json.manager_last_name,
      json.street,
      json.postal_code,
      json.city,
      json.legal_name,
      json.siret,
      json.rcs_city,
      json.ebics_contact_email,
      json.expert_enabled,
      json.partner ? Partner.fromJson(json.partner) : { id: null }
    );
  }

  static toJson(businessUnit: BusinessUnit): any {
    return {
      id: businessUnit.id,
      name: businessUnit.name || null,
      internal: businessUnit.internal,
      cfonb_restitution_email: businessUnit.cfonbRestitutionEmail,
      brand: businessUnit.brand,
      manager_first_name: businessUnit.managerFirstName,
      manager_last_name: businessUnit.managerLastName,
      street: businessUnit.street,
      postal_code: businessUnit.postalCode,
      city: businessUnit.city,
      legal_name: businessUnit.legalName,
      siret: businessUnit.siret,
      rcs_city: businessUnit.rcsCity,
      ebics_contact_email: businessUnit.ebicsContactEmail,
      expert_enabled: businessUnit.expertEnabled,
      partner:
        businessUnit.partner && businessUnit.partner.id
          ? Partner.toJson(businessUnit.partner)
          : null
    };
  }
}
