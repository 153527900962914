import { Component, Inject, ChangeDetectionStrategy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface VersionSwitchConfirmationData {
  title: string;
  message: string;
}

@Component({
  selector: 'app-version-switch-confirmation-dialog',
  templateUrl: './version-switch-confirmation-dialog.component.html',
  styleUrls: ['./version-switch-confirmation-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VersionSwitchConfirmationDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: VersionSwitchConfirmationData,
    public dialogRef: MatDialogRef<VersionSwitchConfirmationDialogComponent>
  ) {}

  confirm(): void {
    this.dialogRef.close(true);
  }

  cancel(): void {
    this.dialogRef.close(false);
  }
}
