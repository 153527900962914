<div class="table-wrapper">
    <div class="table">
        <div class="header-row"></div>
        <div class="row">
            <div>
                <div class="cell first"></div>
                <div class="cell"></div>
            </div>
            <div class="cell last"></div>
        </div>
        <div class="row">
            <div>
                <div class="cell first"></div>
                <div class="cell"></div>
            </div>
            <div class="cell last"></div>
        </div>
        <div class="row">
            <div>
                <div class="cell first"></div>
                <div class="cell"></div>
            </div>
            <div class="cell last"></div>
        </div>
        <div class="row">
            <div>
                <div class="cell first"></div>
                <div class="cell"></div>
            </div>
            <div class="cell last"></div>
        </div>
        <div class="row">
            <div>
                <div class="cell first"></div>
                <div class="cell"></div>
            </div>
            <div class="cell last"></div>
        </div>
        <div class="row">
            <div>
                <div class="cell first"></div>
                <div class="cell"></div>
            </div>
            <div class="cell last"></div>
        </div>
        <div class="row">
            <div>
                <div class="cell first"></div>
                <div class="cell"></div>
            </div>
            <div class="cell last"></div>
        </div>
        <div class="row">
            <div>
                <div class="cell first"></div>
                <div class="cell"></div>
            </div>
            <div class="cell last"></div>
        </div>
        <div class="row">
            <div>
                <div class="cell first"></div>
                <div class="cell"></div>
            </div>
            <div class="cell last"></div>
        </div>
        <div class="row">
            <div>
                <div class="cell first"></div>
                <div class="cell"></div>
            </div>
            <div class="cell last"></div>
        </div>

    </div>
</div>