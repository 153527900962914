import { NgModule, LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeFrExtra from '@angular/common/locales/extra/fr';
import 'hammerjs';

import { AppComponent } from './app.component';
import { CoreModule } from './core';
import { AppRoutingModule } from './app-routing.module';
import { DATE_RELATIVE_TIME_LABEL } from './core/constants/date-relative-time-label.constants';
import { DateHelper } from './core/helpers/date.helper';

registerLocaleData(localeFr, localeFrExtra);
DateHelper.updateLocale('fr', {
  relativeTime: DATE_RELATIVE_TIME_LABEL
});

@NgModule({
  declarations: [AppComponent],
  imports: [CoreModule, AppRoutingModule],
  providers: [{ provide: LOCALE_ID, useValue: 'fr' }],
  bootstrap: [AppComponent]
})
export class AppModule {}
