import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { withLatestFrom, tap } from 'rxjs/operators';

import * as BusinessUserActions from './business-user-actions';
import { businessUserSelector } from './business-user-selector';
import { SentryService } from '../../sentry/sentry.service';
import { BusinessUser } from '../../models/business-user';

@Injectable()
export class BusinessUserEffects {
  constructor(
    private store: Store<any>,
    private actions$: Actions,
    private sentryService: SentryService
  ) {}

  @Effect({ dispatch: false })
  updateAction$ = this.actions$.pipe(
    ofType(BusinessUserActions.UPDATE),
    withLatestFrom(this.store.select(businessUserSelector)),
    tap(
      ([action, businessUser]: [
        BusinessUserActions.UpdateAction,
        BusinessUser
      ]) => this.sentryService.setUserFromBusinessUser(businessUser)
    )
  );
}
