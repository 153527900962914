import * as BusinessUserActions from './business-user-actions';

import { BusinessUser } from '../../models/business-user';

type BusinessUserState = BusinessUser;

export function businessUserReducer(
  state: BusinessUser,
  action: BusinessUserActions.Actions
): BusinessUserState {
  switch (action.type) {
    case BusinessUserActions.UPDATE:
      return updateBusinessUser(state, action.payload);
    default:
      return state;
  }
}

function updateBusinessUser(
  state: BusinessUser,
  businessUser: BusinessUser
): BusinessUser {
  state = new BusinessUser(
    businessUser.id,
    businessUser.firstName,
    businessUser.lastName,
    businessUser.email,
    businessUser.phone,
    businessUser.businessUnits,
    businessUser.hasInternalAccess,
    businessUser.color,
    businessUser.acronym,
    businessUser.businessUnitsRole,
    businessUser.roles
  );
  return state;
}
