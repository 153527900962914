import { VatRate } from './vat-rate';
import { DateHelper } from '../helpers/date.helper';

export class VatType {
  get hasName(): boolean {
    return this.name !== '{{rate}}';
  }

  constructor(
    public code?: string,
    public name?: string,
    public main?: boolean,
    public area?: string,
    public vatRates?: VatRate[],
    public invoiceLegalNotice?: string
  ) {}

  public static fromJson(json): VatType {
    return new VatType(
      json.code,
      json.name,
      json.main,
      json.area,
      json.vat_rates
        ? json.vat_rates.map(vatRateJson => VatRate.fromJson(vatRateJson))
        : [],
      json.invoice_legal_notice
    );
  }

  public static toJson(vatType: VatType): any {
    return {
      code: vatType.code,
      name: vatType.name,
      main: vatType.main,
      area: vatType.area,
      vat_rates: vatType.vatRates
        ? vatType.vatRates.map((vatRate: VatRate) => VatRate.toJson(vatRate))
        : undefined,
      invoice_legal_notice: vatType.invoiceLegalNotice
    };
  }

  vatRateEmissionDateIn(emissionDate?: any): number {
    if (!emissionDate) {
      emissionDate = new Date();
    } else if (DateHelper.isMoment(emissionDate)) {
      emissionDate = DateHelper.toDate(emissionDate);
    } else if (typeof emissionDate === 'string') {
      emissionDate = DateHelper.toDate(emissionDate, ['YYYY-MM-DD']);
    }

    return this.vatRates.find((vatRate: VatRate) =>
      vatRate.isDateIn(emissionDate)
    ).rate;
  }
}
