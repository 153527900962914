import { Router } from '@angular/router';
import { TiimeAuthService } from 'tiime-auth';
import { POST_SIGN_IN_TARGET_PARAM_NAME } from '../auth/auth-callback/auth-callback.component';
import * as i0 from "@angular/core";
import * as i1 from "tiime-auth";
import * as i2 from "@angular/router";
export class AuthGuard {
    constructor(authService, router) {
        this.authService = authService;
        this.router = router;
    }
    canLoad() {
        const isAuthenticated = this.authService.isAuthenticated();
        if (!isAuthenticated) {
            const postSignInTargetPath = this.getPostSignInTargetPathFromNavigation();
            this.router.navigate(['signin'], Object.assign({}, (postSignInTargetPath && {
                queryParams: {
                    [POST_SIGN_IN_TARGET_PARAM_NAME]: postSignInTargetPath
                }
            })));
        }
        return isAuthenticated;
    }
    getPostSignInTargetPathFromNavigation() {
        const navigation = this.router.getCurrentNavigation();
        if (navigation) {
            const path = navigation.extractedUrl.toString();
            return path && path !== '/' ? path : null;
        }
        return null;
    }
}
AuthGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.ɵɵinject(i1.TiimeAuthService), i0.ɵɵinject(i2.Router)); }, token: AuthGuard, providedIn: "root" });
