import * as tslib_1 from "tslib";
import { OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { TiimeAuthService } from 'tiime-auth';
import { MarkFormAsTouchedIfInvalid } from 'tiime-utils';
import { ChangePasswordForm } from './change-password-form';
export class ChangePasswordComponent {
    constructor(authService, cdr, router) {
        this.authService = authService;
        this.cdr = cdr;
        this.router = router;
        this.isMailSent = false;
    }
    ngOnInit() {
        this.initChangePasswordForm();
    }
    submitChangePasswordForm() {
        this.changePassword();
    }
    navigateToSignin() {
        this.router.navigateByUrl('/signin');
    }
    initChangePasswordForm() {
        this.changePasswordForm = new ChangePasswordForm();
    }
    changePassword() {
        this.authService
            .changePassword(this.changePasswordForm.email.value)
            .then(() => this.changePasswordSuccess());
    }
    changePasswordSuccess() {
        this.isMailSent = true;
        this.cdr.markForCheck();
    }
}
tslib_1.__decorate([
    MarkFormAsTouchedIfInvalid('changePasswordForm'),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", []),
    tslib_1.__metadata("design:returntype", void 0)
], ChangePasswordComponent.prototype, "submitChangePasswordForm", null);
