export abstract class UserBase {
  get fullName(): string {
    return this.firstName && this.lastName
      ? `${this.firstName} ${this.lastName}`
      : this.lastName || this.firstName;
  }

  constructor(
    public id?: number,
    public firstName?: string,
    public lastName?: string,
    public email?: string
  ) {}
}
