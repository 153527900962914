/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./business-user-slug.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./business-user-slug.component";
var styles_BusinessUserSlugComponent = [i0.styles];
var RenderType_BusinessUserSlugComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BusinessUserSlugComponent, data: {} });
export { RenderType_BusinessUserSlugComponent as RenderType_BusinessUserSlugComponent };
export function View_BusinessUserSlugComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "business-user-slug-container"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), (_l()(), i1.ɵted(3, null, [" ", "\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.businessUserSlugStyle; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.businessUserAcronym; _ck(_v, 3, 0, currVal_1); }); }
export function View_BusinessUserSlugComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-business-user-slug", [], null, null, null, View_BusinessUserSlugComponent_0, RenderType_BusinessUserSlugComponent)), i1.ɵdid(1, 49152, null, 0, i3.BusinessUserSlugComponent, [], null, null)], null, null); }
var BusinessUserSlugComponentNgFactory = i1.ɵccf("app-business-user-slug", i3.BusinessUserSlugComponent, View_BusinessUserSlugComponent_Host_0, { businessUserAcronym: "businessUserAcronym", businessUserColor: "businessUserColor", setFontSize: "fontSize", setSize: "size" }, {}, []);
export { BusinessUserSlugComponentNgFactory as BusinessUserSlugComponentNgFactory };
