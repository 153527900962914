<div class="close-button" (click)="cancel()">
  <mat-icon inline="true" fontIcon="icon-ic-remove"></mat-icon>
</div>
<mat-dialog-content class="dialog-content" mat-dialog-content>
  <img src="assets/illu_switch_version.svg" alt="Pulse license logo" />
  <h1>{{ data.title }}</h1>
  <p>{{ data.message }}</p>
</mat-dialog-content>
<mat-dialog-actions class="dialog-actions">
  <t2-mat-button accent (click)="cancel()">Annuler</t2-mat-button>
  <t2-mat-button accent raised (click)="confirm()">Valider</t2-mat-button>
</mat-dialog-actions>
