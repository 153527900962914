import { ApplicationRef, enableProdMode, isDevMode } from '@angular/core';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as __NgCli_bootstrap_1 from "./app/app.module.ngfactory";
import * as __NgCli_bootstrap_2 from "@angular/platform-browser";
function enableDebugTools(moduleRef) {
    import('@angular/platform-browser').then(platformBrowser => {
        const applicationRef = moduleRef.injector.get(ApplicationRef);
        const componentRef = applicationRef.components[0];
        platformBrowser.enableDebugTools(componentRef);
    });
}
if (environment.production) {
    enableProdMode();
}
__NgCli_bootstrap_2.platformBrowser()
    .bootstrapModuleFactory(__NgCli_bootstrap_1.AppModuleNgFactory)
    .then((moduleRef) => {
    if (isDevMode()) {
        enableDebugTools(moduleRef);
    }
})
    .catch(err => console.log(err));
