import { Role } from '../enums/role';

export class UserRole {
  constructor(public id?: number, public name?: Role) {}

  static fromJson(json: any): UserRole {
    return new UserRole(json.id, json.name);
  }

  static toJson(userRole: UserRole): any {
    return {
      id: userRole.id
    };
  }
}
