/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../auth-base.scss.shim.ngstyle";
import * as i1 from "./change-password.component.scss.shim.ngstyle";
import * as i2 from "@angular/core";
import * as i3 from "@angular/forms";
import * as i4 from "@angular/common";
import * as i5 from "../../../../../../../dist/tiime-material/tiime-material.ngfactory";
import * as i6 from "tiime-material";
import * as i7 from "../auth-layout/auth-layout.component.ngfactory";
import * as i8 from "../auth-layout/auth-layout.component";
import * as i9 from "@angular/router";
import * as i10 from "./change-password.component";
import * as i11 from "tiime-auth";
var styles_ChangePasswordComponent = [i0.styles, i1.styles];
var RenderType_ChangePasswordComponent = i2.ɵcrt({ encapsulation: 0, styles: styles_ChangePasswordComponent, data: {} });
export { RenderType_ChangePasswordComponent as RenderType_ChangePasswordComponent };
function View_ChangePasswordComponent_1(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 0, "div", [], null, null, null, null, null))], null, null); }
function View_ChangePasswordComponent_3(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "label", [["class", "input-error-label"]], null, null, null, null, null)), (_l()(), i2.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.changePasswordForm.emailErrorMessage; _ck(_v, 1, 0, currVal_0); }); }
function View_ChangePasswordComponent_2(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 14, "div", [["class", "auth-form-content"]], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 1, "span", [["class", "info"]], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, ["Je saisis mon adresse mail"])), (_l()(), i2.ɵeld(3, 0, null, null, 8, "div", [["class", "input-container"]], null, null, null, null, null)), (_l()(), i2.ɵeld(4, 0, null, null, 5, "input", [["formControlName", "email"], ["placeholder", "Adresse email"], ["type", "text"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; if (("input" === en)) {
        var pd_0 = (i2.ɵnov(_v, 5)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i2.ɵnov(_v, 5).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i2.ɵnov(_v, 5)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i2.ɵnov(_v, 5)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i2.ɵdid(5, 16384, null, 0, i3.DefaultValueAccessor, [i2.Renderer2, i2.ElementRef, [2, i3.COMPOSITION_BUFFER_MODE]], null, null), i2.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.DefaultValueAccessor]), i2.ɵdid(7, 671744, null, 0, i3.FormControlName, [[3, i3.ControlContainer], [8, null], [8, null], [6, i3.NG_VALUE_ACCESSOR], [2, i3.ɵangular_packages_forms_forms_q]], { name: [0, "name"] }, null), i2.ɵprd(2048, null, i3.NgControl, null, [i3.FormControlName]), i2.ɵdid(9, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_ChangePasswordComponent_3)), i2.ɵdid(11, 16384, null, 0, i4.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i2.ɵeld(12, 0, null, null, 2, "t2-mat-button", [["accent", ""], ["raised", ""]], null, null, null, i5.View_ButtonComponent_0, i5.RenderType_ButtonComponent)), i2.ɵdid(13, 49152, null, 0, i6.ButtonComponent, [i2.ElementRef], { raised: [0, "raised"] }, null), (_l()(), i2.ɵted(-1, 0, ["Envoyer l'email"]))], function (_ck, _v) { var _co = _v.component; var currVal_7 = "email"; _ck(_v, 7, 0, currVal_7); var currVal_8 = _co.changePasswordForm.emailErrorMessage; _ck(_v, 11, 0, currVal_8); var currVal_9 = ""; _ck(_v, 13, 0, currVal_9); }, function (_ck, _v) { var currVal_0 = i2.ɵnov(_v, 9).ngClassUntouched; var currVal_1 = i2.ɵnov(_v, 9).ngClassTouched; var currVal_2 = i2.ɵnov(_v, 9).ngClassPristine; var currVal_3 = i2.ɵnov(_v, 9).ngClassDirty; var currVal_4 = i2.ɵnov(_v, 9).ngClassValid; var currVal_5 = i2.ɵnov(_v, 9).ngClassInvalid; var currVal_6 = i2.ɵnov(_v, 9).ngClassPending; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_ChangePasswordComponent_4(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 5, "div", [["class", "auth-form-content"]], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 1, "span", [["class", "info"]], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, ["Un email vient d'\u00EAtre envoy\u00E9 pour r\u00E9initialiser le mot de passe"])), (_l()(), i2.ɵeld(3, 0, null, null, 2, "t2-mat-button", [["accent", ""], ["raised", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigateToSignin() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_ButtonComponent_0, i5.RenderType_ButtonComponent)), i2.ɵdid(4, 49152, null, 0, i6.ButtonComponent, [i2.ElementRef], { raised: [0, "raised"] }, null), (_l()(), i2.ɵted(-1, 0, ["R\u00E9-essayer de me connecter"]))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 4, 0, currVal_0); }, null); }
export function View_ChangePasswordComponent_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 21, "app-auth-layout", [], null, null, null, i7.View_AuthLayoutComponent_0, i7.RenderType_AuthLayoutComponent)), i2.ɵdid(1, 49152, null, 0, i8.AuthLayoutComponent, [], null, null), (_l()(), i2.ɵeld(2, 0, null, 0, 19, "form", [["class", "auth-form"], ["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngSubmit"], [null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("submit" === en)) {
        var pd_0 = (i2.ɵnov(_v, 4).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i2.ɵnov(_v, 4).onReset() !== false);
        ad = (pd_1 && ad);
    } if (("ngSubmit" === en)) {
        var pd_2 = (_co.submitChangePasswordForm() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i2.ɵdid(3, 16384, null, 0, i3.ɵangular_packages_forms_forms_z, [], null, null), i2.ɵdid(4, 540672, null, 0, i3.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, { ngSubmit: "ngSubmit" }), i2.ɵprd(2048, null, i3.ControlContainer, null, [i3.FormGroupDirective]), i2.ɵdid(6, 16384, null, 0, i3.NgControlStatusGroup, [[4, i3.ControlContainer]], null, null), (_l()(), i2.ɵeld(7, 0, null, null, 4, "div", [["class", "auth-form-header"]], null, null, null, null, null)), (_l()(), i2.ɵeld(8, 0, null, null, 0, "div", [["class", "auth-form-header-illustration"]], null, null, null, null, null)), (_l()(), i2.ɵeld(9, 0, null, null, 0, "div", [["class", "auth-form-header-separator"]], null, null, null, null, null)), (_l()(), i2.ɵeld(10, 0, null, null, 1, "span", [["class", "auth-form-header-title"]], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, ["Modifier mon mot de passe"])), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_ChangePasswordComponent_1)), i2.ɵdid(13, 16384, null, 0, i4.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"], ngIfThen: [1, "ngIfThen"], ngIfElse: [2, "ngIfElse"] }, null), (_l()(), i2.ɵand(0, [["form", 2]], null, 0, null, View_ChangePasswordComponent_2)), (_l()(), i2.ɵand(0, [["mailSent", 2]], null, 0, null, View_ChangePasswordComponent_4)), (_l()(), i2.ɵeld(16, 0, null, null, 5, "div", [["class", "auth-form-link bottom"]], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, [" D\u00E9j\u00E0 un compte ? "])), (_l()(), i2.ɵeld(18, 0, null, null, 3, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i2.ɵnov(_v, 19).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i2.ɵdid(19, 671744, null, 0, i9.RouterLinkWithHref, [i9.Router, i9.ActivatedRoute, i4.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i2.ɵpad(20, 1), (_l()(), i2.ɵted(-1, null, ["Je me connecte sur Tiime Pulse !"]))], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.changePasswordForm; _ck(_v, 4, 0, currVal_7); var currVal_8 = _co.isMailSent; var currVal_9 = i2.ɵnov(_v, 15); var currVal_10 = i2.ɵnov(_v, 14); _ck(_v, 13, 0, currVal_8, currVal_9, currVal_10); var currVal_13 = _ck(_v, 20, 0, "/signin"); _ck(_v, 19, 0, currVal_13); }, function (_ck, _v) { var currVal_0 = i2.ɵnov(_v, 6).ngClassUntouched; var currVal_1 = i2.ɵnov(_v, 6).ngClassTouched; var currVal_2 = i2.ɵnov(_v, 6).ngClassPristine; var currVal_3 = i2.ɵnov(_v, 6).ngClassDirty; var currVal_4 = i2.ɵnov(_v, 6).ngClassValid; var currVal_5 = i2.ɵnov(_v, 6).ngClassInvalid; var currVal_6 = i2.ɵnov(_v, 6).ngClassPending; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_11 = i2.ɵnov(_v, 19).target; var currVal_12 = i2.ɵnov(_v, 19).href; _ck(_v, 18, 0, currVal_11, currVal_12); }); }
export function View_ChangePasswordComponent_Host_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "app-change-password", [], null, null, null, View_ChangePasswordComponent_0, RenderType_ChangePasswordComponent)), i2.ɵdid(1, 114688, null, 0, i10.ChangePasswordComponent, [i11.TiimeAuthService, i2.ChangeDetectorRef, i9.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ChangePasswordComponentNgFactory = i2.ɵccf("app-change-password", i10.ChangePasswordComponent, View_ChangePasswordComponent_Host_0, {}, {}, []);
export { ChangePasswordComponentNgFactory as ChangePasswordComponentNgFactory };
