/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./auth-layout.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./auth-layout.component";
var styles_AuthLayoutComponent = [i0.styles];
var RenderType_AuthLayoutComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_AuthLayoutComponent, data: {} });
export { RenderType_AuthLayoutComponent as RenderType_AuthLayoutComponent };
export function View_AuthLayoutComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "auth-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "auth-left-container"]], null, null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵeld(3, 0, null, null, 11, "div", [["class", "auth-right-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 10, "div", [["class", "auth-right-container-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [["alt", "illu tiime pulse parrainage"], ["class", "content-img"], ["src", "assets/illu_tiime_pulse_parrainage.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["class", "content-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Parlez de Tiime, parrainez un confr\u00E8re ! "])), (_l()(), i1.ɵeld(8, 0, null, null, 3, "span", [["class", "content-description"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" C\u2019est encore vous qui en parlez le mieux ! Nous serions ravis de leur faire d\u00E9couvrir nos solutions gr\u00E2ce \u00E0 votre mise en relation. "])), (_l()(), i1.ɵeld(10, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Vous \u00EAtes Expert comptable ? Gagnez 1 mois gratuit pour vous et votre filleul. Collaborateur ? Gagnez un cadeau. "])), (_l()(), i1.ɵeld(12, 0, null, null, 2, "div", [["class", "content-action"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "a", [["class", "referral-action"], ["href", "https://tiime.typeform.com/to/bys72mrI"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Je parraine un confr\u00E8re "]))], null, null); }
export function View_AuthLayoutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-auth-layout", [], null, null, null, View_AuthLayoutComponent_0, RenderType_AuthLayoutComponent)), i1.ɵdid(1, 49152, null, 0, i2.AuthLayoutComponent, [], null, null)], null, null); }
var AuthLayoutComponentNgFactory = i1.ɵccf("app-auth-layout", i2.AuthLayoutComponent, View_AuthLayoutComponent_Host_0, {}, {}, ["*"]);
export { AuthLayoutComponentNgFactory as AuthLayoutComponentNgFactory };
