<div class="business-user-account-menu-overlay-header">
  <app-business-user-slug
    [businessUserAcronym]="businessUser.acronym"
    [businessUserColor]="businessUser.color"
    fontSize="30px"
    size="80px"
  ></app-business-user-slug>
</div>
<div class="business-user-account-menu-overlay-content">
  <div class="business-user-account">Compte Tiime Pulse</div>
  <div class="business-user-infos">{{ businessUser.fullName }}</div>
  <div class="business-user-infos">{{ businessUser.email }}</div>
  <div class="update-password" (click)="changePassword()">
    Changer de mot de passe
  </div>
  <t2-mat-button discrete (click)="logout()">Déconnexion</t2-mat-button>
</div>
