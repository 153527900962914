import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthCallbackComponent } from './core/auth/auth-callback/auth-callback.component';
import { SigninComponent } from './core/auth/signin/signin.component';
import { ChangePasswordComponent } from './core/auth/change-password/change-password.component';
import { BusinessUserGuard } from './core/guards/business-user.guard';
import { NotFoundComponent } from './core/not-found/not-found.component';
import { UnauthorizedComponent } from './core/unauthorized/unauthorized.component';
import { BusinessAdminGuard } from './core/guards/business-admin.guard';
import { AuthGuard } from './core/guards/auth.guard';
import { BusinessAgentGuard } from './core/guards/business-agent.guard';

const routes: Routes = [
  { path: '', redirectTo: 'pro', pathMatch: 'full' },
  { path: 'signin', component: SigninComponent },
  { path: 'changepassword', component: ChangePasswordComponent },
  { path: 'auth-callback', component: AuthCallbackComponent },
  {
    path: 'pro',
    loadChildren: () => import('./pro/pro.module').then(m => m.ProModule),
    canLoad: [AuthGuard, BusinessUserGuard, BusinessAgentGuard]
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    canLoad: [AuthGuard, BusinessUserGuard, BusinessAdminGuard]
  },
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: 'not-found', component: NotFoundComponent },
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
