<div class="subheader-wrapper">
  <div class="subheader">
    <div class="left">
      <ng-content select="[subheader-title]"></ng-content>
      <ng-content select="[searchbar]"></ng-content>
      <mat-progress-spinner *ngIf="loading" diameter="25" color="primary" mode="indeterminate"></mat-progress-spinner>
    </div>
    <ng-content select="[content]"></ng-content>
    <div class="right">
      <ng-content select="[actions]"></ng-content>
      <ng-content select="[more-actions]"></ng-content>
    </div>
  </div>
</div>
