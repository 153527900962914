import { NgModule, Injector } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TextMaskModule } from 'angular2-text-mask';
import { ClipboardModule } from 'ngx-clipboard';
import { LottieModule } from 'ngx-lottie';
import { TiimePipesModule } from 'tiime-utils';

import { MaterialModule } from './material/material.module';
import * as Components from './components';
import * as Pipes from './pipes';
import { setSharedInjector } from './shared-injector';

export function lottiePlayerFactory() {
  return import('lottie-web');
}

@NgModule({
  imports: [
    MaterialModule,
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    TextMaskModule,
    LottieModule.forRoot({ player: lottiePlayerFactory, useCache: true })
  ],
  declarations: [
    Components.EmptyStateSearchComponent,
    Components.ConfirmDialogComponent,
    Components.AutoSaveStatusComponent,
    Components.VersionSwitchConfirmationDialogComponent,
    Components.LogoComponent,
    Components.LoaderComponent,
    Components.HeaderComponent,
    Components.BusinessUserAccountMenuComponent,
    Components.BusinessUserSlugComponent,
    Components.BusinessUserAccountMenuOverlayComponent,
    Components.AppLauncherOverlayComponent,
    Components.AppLauncherSelectorComponent,
    Components.SidenavComponent,
    Components.SidenavItemComponent,
    Components.ImageZoomComponent,
    Pipes.BusinessUnitsNamesPipe,
    Pipes.CompanyCounterCountByTypePipe,
    Pipes.AccountantDetailRequestTypeLabelPipe,
    Pipes.CompanyReminderDateByTypePipe,
    Pipes.AggregationStatusLabelPipe,
    Pipes.AggregationStatusDescriptionPipe,
    Pipes.EbicsStatusLabelPipe,
    Pipes.EbicsStatusDescriptionPipe,
    Pipes.SecretIbanPipe,
    Pipes.OneTimeFormControlValuePipe
  ],
  exports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    MaterialModule,
    TiimePipesModule,
    TextMaskModule,
    LottieModule,
    ClipboardModule,
    Components.EmptyStateSearchComponent,
    Components.ConfirmDialogComponent,
    Components.AutoSaveStatusComponent,
    Components.VersionSwitchConfirmationDialogComponent,
    Components.LogoComponent,
    Components.LoaderComponent,
    Components.HeaderComponent,
    Components.BusinessUserAccountMenuComponent,
    Components.BusinessUserSlugComponent,
    Components.BusinessUserAccountMenuOverlayComponent,
    Components.AppLauncherOverlayComponent,
    Components.AppLauncherSelectorComponent,
    Components.SidenavComponent,
    Components.SidenavItemComponent,
    Components.ImageZoomComponent,
    Pipes.BusinessUnitsNamesPipe,
    Pipes.CompanyCounterCountByTypePipe,
    Pipes.AccountantDetailRequestTypeLabelPipe,
    Pipes.CompanyReminderDateByTypePipe,
    Pipes.AggregationStatusLabelPipe,
    Pipes.AggregationStatusDescriptionPipe,
    Pipes.EbicsStatusLabelPipe,
    Pipes.EbicsStatusDescriptionPipe,
    Pipes.SecretIbanPipe,
    Pipes.OneTimeFormControlValuePipe
  ],
  entryComponents: [
    Components.ConfirmDialogComponent,
    Components.VersionSwitchConfirmationDialogComponent,
    Components.BusinessUserAccountMenuOverlayComponent,
    Components.AppLauncherOverlayComponent
  ]
})
export class SharedModule {
  constructor(injector: Injector) {
    setSharedInjector(injector);
  }
}
