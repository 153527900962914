/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./business-user-account-menu-overlay.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../business-user-slug/business-user-slug.component.ngfactory";
import * as i3 from "../business-user-slug/business-user-slug.component";
import * as i4 from "../../../../../../../dist/tiime-material/tiime-material.ngfactory";
import * as i5 from "tiime-material";
import * as i6 from "./business-user-account-menu-overlay.component";
import * as i7 from "tiime-auth";
import * as i8 from "../../../core/sentry/sentry.service";
var styles_BusinessUserAccountMenuOverlayComponent = [i0.styles];
var RenderType_BusinessUserAccountMenuOverlayComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BusinessUserAccountMenuOverlayComponent, data: {} });
export { RenderType_BusinessUserAccountMenuOverlayComponent as RenderType_BusinessUserAccountMenuOverlayComponent };
export function View_BusinessUserAccountMenuOverlayComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "business-user-account-menu-overlay-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-business-user-slug", [["fontSize", "30px"], ["size", "80px"]], null, null, null, i2.View_BusinessUserSlugComponent_0, i2.RenderType_BusinessUserSlugComponent)), i1.ɵdid(2, 49152, null, 0, i3.BusinessUserSlugComponent, [], { businessUserAcronym: [0, "businessUserAcronym"], businessUserColor: [1, "businessUserColor"], setFontSize: [2, "setFontSize"], setSize: [3, "setSize"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 11, "div", [["class", "business-user-account-menu-overlay-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "business-user-account"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Compte Tiime Pulse"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "business-user-infos"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "business-user-infos"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "div", [["class", "update-password"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changePassword() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Changer de mot de passe "])), (_l()(), i1.ɵeld(12, 0, null, null, 2, "t2-mat-button", [["discrete", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.logout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_ButtonComponent_0, i4.RenderType_ButtonComponent)), i1.ɵdid(13, 49152, null, 0, i5.ButtonComponent, [i1.ElementRef], null, null), (_l()(), i1.ɵted(-1, 0, ["D\u00E9connexion"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.businessUser.acronym; var currVal_1 = _co.businessUser.color; var currVal_2 = "30px"; var currVal_3 = "80px"; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.businessUser.fullName; _ck(_v, 7, 0, currVal_4); var currVal_5 = _co.businessUser.email; _ck(_v, 9, 0, currVal_5); }); }
export function View_BusinessUserAccountMenuOverlayComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-business-user-account-menu-overlay", [], null, null, null, View_BusinessUserAccountMenuOverlayComponent_0, RenderType_BusinessUserAccountMenuOverlayComponent)), i1.ɵdid(1, 49152, null, 0, i6.BusinessUserAccountMenuOverlayComponent, [i7.TiimeAuthService, i8.SentryService, i5.TiimeSnackbarService, i5.TIIME_OVERLAY_DATA], null, null)], null, null); }
var BusinessUserAccountMenuOverlayComponentNgFactory = i1.ɵccf("app-business-user-account-menu-overlay", i6.BusinessUserAccountMenuOverlayComponent, View_BusinessUserAccountMenuOverlayComponent_Host_0, {}, {}, []);
export { BusinessUserAccountMenuOverlayComponentNgFactory as BusinessUserAccountMenuOverlayComponentNgFactory };
