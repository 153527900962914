<mat-dialog-content class="dialog-content" mat-dialog-content>
  <span class="title">{{ data.confirmTitle }}</span>
  <span class="message">{{ data.confirmMessage }}</span>
</mat-dialog-content>
<mat-dialog-actions class="dialog-actions">
  <t2-mat-button accent raised (click)="confirm()">{{
    data.confirmAction
  }}</t2-mat-button>
  <t2-mat-button accent (click)="cancel()">{{
    data.cancelAction
  }}</t2-mat-button>
</mat-dialog-actions>
