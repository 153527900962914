import { Store, select } from '@ngrx/store';
import { of } from 'rxjs';
import { map, take, switchMap, catchError, tap } from 'rxjs/operators';
import * as BusinessUserActions from '../store/business-user/business-user-actions';
import { ApiService } from '../api.service';
import { businessUserSelector } from '../store/business-user/business-user-selector';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
import * as i2 from "../api.service";
export class BusinessUserGuard {
    constructor(store, apiService) {
        this.store = store;
        this.apiService = apiService;
    }
    canActivate() {
        return this.hasBusinessUser();
    }
    canLoad() {
        return this.hasBusinessUser();
    }
    hasBusinessUserInStore() {
        return this.store.pipe(select(businessUserSelector), map((businessUser) => !!businessUser), take(1));
    }
    hasBusinessUserInApi() {
        return this.apiService.getBusinessUser().pipe(tap((businessUser) => this.store.dispatch(new BusinessUserActions.UpdateAction(businessUser))), catchError(() => of(false)), switchMap(() => this.hasBusinessUserInStore()));
    }
    hasBusinessUser() {
        return this.hasBusinessUserInStore().pipe(switchMap((inStore) => inStore ? of(inStore) : this.hasBusinessUserInApi()));
    }
}
BusinessUserGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BusinessUserGuard_Factory() { return new BusinessUserGuard(i0.ɵɵinject(i1.Store), i0.ɵɵinject(i2.ApiService)); }, token: BusinessUserGuard, providedIn: "root" });
