export class VatRate {
  constructor(
    public startDate?: string,
    public endDate?: string,
    public rate?: number
  ) {}

  public static fromJson(json): VatRate {
    return new VatRate(json.start_date, json.end_date, json.rate);
  }

  public static toJson(vatRate: VatRate): any {
    return {
      start_date: vatRate.startDate,
      end_date: vatRate.endDate,
      rate: vatRate.rate
    };
  }

  public isDateIn(date: Date = new Date()): boolean {
    const startDate = new Date(this.startDate);
    const endDate = new Date(this.endDate);
    endDate.setDate(endDate.getDate() + 1);

    if (!this.startDate && date < endDate) {
      return true;
    }

    if (!this.endDate && startDate <= date) {
      return true;
    }

    if (startDate <= date && date < endDate) {
      return true;
    }

    return false;
  }
}
