/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./auth-callback.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./auth-callback.component";
import * as i3 from "../../api.service";
import * as i4 from "tiime-auth";
import * as i5 from "../../tokens/query-params-map.token";
import * as i6 from "@angular/router";
import * as i7 from "@ngrx/store";
import * as i8 from "../../tokens/window.token";
var styles_AuthCallbackComponent = [i0.styles];
var RenderType_AuthCallbackComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AuthCallbackComponent, data: {} });
export { RenderType_AuthCallbackComponent as RenderType_AuthCallbackComponent };
export function View_AuthCallbackComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["src", "assets/logo_tiime_pulse_horizontal.svg"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00A0 "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "d"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["."])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["class", "d d-2"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["."])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["class", "d d-3"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["."]))], null, null); }
export function View_AuthCallbackComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-auth-callback", [], null, null, null, View_AuthCallbackComponent_0, RenderType_AuthCallbackComponent)), i1.ɵdid(1, 245760, null, 0, i2.AuthCallbackComponent, [i3.ApiService, i4.TiimeAuthService, i5.QUERY_PARAMS_MAP, i6.Router, i7.Store, i8.WINDOW], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AuthCallbackComponentNgFactory = i1.ɵccf("app-auth-callback", i2.AuthCallbackComponent, View_AuthCallbackComponent_Host_0, {}, {}, []);
export { AuthCallbackComponentNgFactory as AuthCallbackComponentNgFactory };
