/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./unauthorized.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./unauthorized.component";
var styles_UnauthorizedComponent = [i0.styles];
var RenderType_UnauthorizedComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UnauthorizedComponent, data: {} });
export { RenderType_UnauthorizedComponent as RenderType_UnauthorizedComponent };
export function View_UnauthorizedComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "not-found-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["alt", "404 illustration"], ["class", "illustration"], ["src", "assets/illu_403_grey.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "description"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Acc\u00E8s refus\u00E9"]))], null, null); }
export function View_UnauthorizedComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-unauthorized", [], null, null, null, View_UnauthorizedComponent_0, RenderType_UnauthorizedComponent)), i1.ɵdid(1, 49152, null, 0, i2.UnauthorizedComponent, [], null, null)], null, null); }
var UnauthorizedComponentNgFactory = i1.ɵccf("app-unauthorized", i2.UnauthorizedComponent, View_UnauthorizedComponent_Host_0, {}, {}, []);
export { UnauthorizedComponentNgFactory as UnauthorizedComponentNgFactory };
