import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ParamMap, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { from, Observable, Subscription } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { TiimeAuthService } from 'tiime-auth';
import { AutoUnsubscribe } from 'tiime-utils';

import { ApiService } from '../../api.service';
import { Role } from '../../enums/role';
import * as BusinessUserActions from '../../store/business-user/business-user-actions';
import { BusinessUser } from '../../models/business-user';
import { QUERY_PARAMS_MAP } from '../../tokens/query-params-map.token';
import { WINDOW } from '../../tokens/window.token';
import { businessUserRolesSelector } from '../../store/business-user/business-user-selector';

export const POST_SIGN_IN_TARGET_PARAM_NAME = 'target';

@Component({
  selector: 'app-auth-callback',
  templateUrl: './auth-callback.component.html',
  styleUrls: ['./auth-callback.component.scss']
})
@AutoUnsubscribe()
export class AuthCallbackComponent implements OnInit, OnDestroy {
  private initAuthenticationSub: Subscription;

  constructor(
    private apiService: ApiService,
    private authService: TiimeAuthService,
    @Inject(QUERY_PARAMS_MAP) private queryParamsMap$: Observable<ParamMap>,
    private router: Router,
    private store: Store<any>,
    @Inject(WINDOW) private windowRef: Window
  ) {}

  ngOnInit() {
    this.initAuthenticationSub = from(this.authService.parseHash())
      .pipe(
        switchMap(() => this.initBusinessUser()),
        switchMap(() => this.handleRedirection()),
        catchError(() => this.navigateToSignIn())
      )
      .subscribe();
  }

  ngOnDestroy(): void {}

  private handleRedirection(): Observable<boolean> {
    return this.queryParamsMap$.pipe(
      switchMap((paramMap: ParamMap) => {
        const postSignInTargetPath = paramMap.get(
          POST_SIGN_IN_TARGET_PARAM_NAME
        );
        return postSignInTargetPath
          ? this.navigateToPostSignInTarget(postSignInTargetPath)
          : this.navigateToDefaultRoute();
      })
    );
  }

  private initBusinessUser(): Observable<BusinessUser> {
    return this.apiService
      .getBusinessUser()
      .pipe(
        tap((businessUser: BusinessUser) =>
          this.store.dispatch(
            new BusinessUserActions.UpdateAction(businessUser)
          )
        )
      );
  }

  private navigateToDefaultRoute(): Observable<boolean> {
    return this.store.pipe(
      select(businessUserRolesSelector),
      switchMap((roles: Role[]) => {
        const navigationCommand =
          roles.length === 1 && roles[0] === Role.BUSINESS_ADMIN
            ? ['/admin']
            : ['/pro'];
        return from(this.router.navigate(navigationCommand));
      })
    );
  }

  private navigateToPostSignInTarget(
    postSignInTargetPath: string
  ): Observable<boolean> {
    const postSignInTargetURL = new URL(
      `${this.windowRef.location.origin}${postSignInTargetPath}`
    );
    const queryParams = {};
    postSignInTargetURL.searchParams.forEach(
      (value: string, key: string) => (queryParams[key] = value)
    );
    return from(
      this.router.navigate([postSignInTargetURL.pathname], {
        queryParams
      })
    );
  }

  private navigateToSignIn(): Observable<boolean> {
    return from(this.router.navigate(['signin']));
  }
}
