import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { VatType } from './models/vat-type';
import { ApiAlertError } from './decorators/api-alert-error';
import { VatSystem } from './models/vat_system';
import { ApeCode } from './models/ape_code';
import { BusinessUser } from './models/business-user';
import { IncomeCategory } from './models/income_category';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class ApiService {
    constructor(http) {
        this.http = http;
    }
    getApeCodes() {
        if (!this.apeCodesCache) {
            const url = `api/v1/ape_codes`;
            this.apeCodesCache = this.http.get(url).pipe(map((apeCodesJson) => apeCodesJson.map((apeCodeJson) => ApeCode.fromJson(apeCodeJson))), shareReplay({ refCount: true, bufferSize: 1 }));
        }
        return this.apeCodesCache;
    }
    getBusinessUser() {
        const url = 'api/v1/pro/business_users/me';
        return this.http
            .get(url)
            .pipe(map((businessUserJson) => BusinessUser.fromJson(businessUserJson)));
    }
    getIncomeCategories() {
        if (!this.incomeCategoriesCache) {
            const url = 'api/v1/income_categories';
            this.incomeCategoriesCache = this.http.get(url).pipe(map((incomeCategoriesJson) => incomeCategoriesJson.map((incomeCategoryJson) => IncomeCategory.fromJson(incomeCategoryJson))), shareReplay({ refCount: true, bufferSize: 1 }));
        }
        return this.incomeCategoriesCache;
    }
    getInvoicingVatTypes() {
        if (!this.invoicingVatTypesCache) {
            const url = `api/v1/pro/invoicing_config/vat_types`;
            this.invoicingVatTypesCache = this.http.get(url).pipe(map((vatTypesJson) => vatTypesJson.map((vatTypeJson) => VatType.fromJson(vatTypeJson))), shareReplay({ refCount: true, bufferSize: 1 }));
        }
        return this.invoicingVatTypesCache;
    }
    getLegalForms() {
        if (!this.legalFormsCache) {
            const url = `api/v1/legal_forms`;
            this.legalFormsCache = this.http.get(url).pipe(map((legalForms) => legalForms.filter((legalForm) => legalForm !== 'UserApp')), shareReplay({ refCount: true, bufferSize: 1 }));
        }
        return this.legalFormsCache;
    }
    getVatSystems() {
        if (!this.vatSystemsCache) {
            const url = `api/v1/vat_systems`;
            this.vatSystemsCache = this.http.get(url).pipe(map((vatSystemsJson) => vatSystemsJson.map((vatSystemJson) => VatSystem.fromJson(vatSystemJson))), shareReplay({ refCount: true, bufferSize: 1 }));
        }
        return this.vatSystemsCache;
    }
    getVatTypes() {
        if (!this.vatTypesCache) {
            const url = `api/v1/pro/vat_types`;
            this.vatTypesCache = this.http.get(url).pipe(map((vatTypesJson) => vatTypesJson.map((vatTypeJson) => VatType.fromJson(vatTypeJson))), shareReplay({ refCount: true, bufferSize: 1 }));
        }
        return this.vatTypesCache;
    }
}
ApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ApiService_Factory() { return new ApiService(i0.ɵɵinject(i1.HttpClient)); }, token: ApiService, providedIn: "root" });
tslib_1.__decorate([
    ApiAlertError(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", []),
    tslib_1.__metadata("design:returntype", Observable)
], ApiService.prototype, "getApeCodes", null);
tslib_1.__decorate([
    ApiAlertError(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", []),
    tslib_1.__metadata("design:returntype", Observable)
], ApiService.prototype, "getBusinessUser", null);
tslib_1.__decorate([
    ApiAlertError(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", []),
    tslib_1.__metadata("design:returntype", Observable)
], ApiService.prototype, "getIncomeCategories", null);
tslib_1.__decorate([
    ApiAlertError(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", []),
    tslib_1.__metadata("design:returntype", Observable)
], ApiService.prototype, "getInvoicingVatTypes", null);
tslib_1.__decorate([
    ApiAlertError(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", []),
    tslib_1.__metadata("design:returntype", Observable)
], ApiService.prototype, "getLegalForms", null);
tslib_1.__decorate([
    ApiAlertError(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", []),
    tslib_1.__metadata("design:returntype", Observable)
], ApiService.prototype, "getVatSystems", null);
tslib_1.__decorate([
    ApiAlertError(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", []),
    tslib_1.__metadata("design:returntype", Observable)
], ApiService.prototype, "getVatTypes", null);
