<div class="t2-mat-tag" [ngClass]="colorClass">
  <span
    t2MatTooltip
    [content]="tagTooltipTemplate"
    tooltipPosition="bottom"
    hideWithoutEllipsis
    >{{ tag }}</span
  >
</div>
<ng-template #tagTooltipTemplate>
  <div class="template-tooltip" [ngClass]="colorClass">
    {{ tag }}
  </div>
</ng-template>
