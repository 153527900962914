import { NgModule } from '@angular/core';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { Overlay, RepositionScrollStrategy } from '@angular/cdk/overlay';
import { CdkStepperModule } from '@angular/cdk/stepper';
import {
  MAT_AUTOCOMPLETE_SCROLL_STRATEGY,
  MatAutocompleteModule
} from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import {
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MAT_RIPPLE_GLOBAL_OPTIONS
} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MomentDateModule } from '@angular/material-moment-adapter';
import { MatExpansionModule } from '@angular/material/expansion';
import {
  TIIME_DATE_FORMATS,
  TIIME_RIPPLE_GLOBAL_OPTIONS,
  TiimeBadgeModule,
  TiimeButtonModule,
  TiimeButtonToggleModule,
  TiimeCheckboxModule,
  TiimeDateListCellModule,
  TiimeDatepickerModule,
  TiimeDialogModule,
  TiimeEmptyStateModule,
  TiimeFileModule,
  TiimeFilterSearchModule,
  TiimeIconModule,
  TiimeInputContainerModule,
  TiimeLoaderModule,
  TiimeMenuModule,
  TiimeMultilineEllipsisModule,
  TiimeOverlayModule,
  TiimePaginatorModule,
  TiimeProgressBarModule,
  TiimeRadioModule,
  TiimeSearchBarModule,
  TiimeSlideToggleModule,
  TiimeSnackbarModule,
  TiimeSortModule,
  TiimeSubheaderModule,
  TiimeTableModule,
  TiimeTagModule,
  TiimeTooltipModule,
  TiimeTwocolorsIconModule,
  TiimeAutocompleteInputModule
} from 'tiime-material';

export function scrollFactory(
  overlay: Overlay
): () => RepositionScrollStrategy {
  return () => overlay.scrollStrategies.reposition();
}

@NgModule({
  imports: [MomentDateModule],
  providers: [
    {
      provide: MAT_RIPPLE_GLOBAL_OPTIONS,
      useValue: TIIME_RIPPLE_GLOBAL_OPTIONS
    },
    { provide: MAT_DATE_FORMATS, useValue: TIIME_DATE_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
    {
      provide: MAT_AUTOCOMPLETE_SCROLL_STRATEGY,
      useFactory: scrollFactory,
      deps: [Overlay]
    }
  ],
  exports: [
    CdkStepperModule,
    MatAutocompleteModule,
    MatMenuModule,
    MatRadioModule,
    MatDatepickerModule,
    MatChipsModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
    ScrollingModule,
    TiimeSlideToggleModule,
    TiimeButtonModule,
    TiimeButtonToggleModule,
    TiimePaginatorModule,
    TiimeTableModule,
    TiimeSearchBarModule,
    TiimeTagModule,
    TiimeIconModule,
    TiimeSnackbarModule,
    TiimeSubheaderModule,
    TiimeEmptyStateModule,
    TiimeMenuModule,
    TiimeDialogModule,
    TiimeInputContainerModule,
    TiimeTwocolorsIconModule,
    TiimeTooltipModule,
    TiimeFilterSearchModule,
    TiimeOverlayModule,
    TiimeBadgeModule,
    TiimeSortModule,
    TiimeCheckboxModule,
    TiimeRadioModule,
    TiimeDatepickerModule,
    TiimeProgressBarModule,
    TiimeLoaderModule,
    TiimeDateListCellModule,
    TiimeMultilineEllipsisModule,
    TiimeFileModule,
    TiimeAutocompleteInputModule
  ]
})
export class MaterialModule {}
