import { BusinessUnit } from './business-unit';
import { CompanyStatus } from '../enums/company-status.enum';

export abstract class CompanyBase {
  get firstBusinessUnit(): BusinessUnit {
    return this.businessUnits[0];
  }
  get isFreemium(): boolean {
    return this.status === CompanyStatus.Freemium;
  }

  constructor(
    public id: number,
    public name: string,
    public businessUnits: BusinessUnit[],
    public status: CompanyStatus,
    public legalForm: string
  ) {}
}
