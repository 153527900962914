import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-business-user-slug',
  templateUrl: './business-user-slug.component.html',
  styleUrls: ['./business-user-slug.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BusinessUserSlugComponent {
  @Input() businessUserAcronym: string;
  @Input() businessUserColor: string;
  @Input('fontSize')
  set setFontSize(fontSize: string) {
    if (fontSize) {
      this.fontSize = fontSize;
    }
  }
  @Input('size')
  set setSize(size: string) {
    if (size) {
      this.size = size;
    }
  }

  private fontSize = '14px';
  private size = '40px';

  get businessUserSlugStyle(): object {
    return {
      background: this.businessUserColor,
      width: this.size,
      height: this.size,
      'line-height': this.size,
      'border-radius': this.size,
      'font-size': this.fontSize
    };
  }

  constructor() {}
}
