<div class="mat-table-container">
  <div
    class="mat-table-container-onboard"
    *ngIf="tableState === TableState.onboard"
  >
    <ng-content select="[onboard]"></ng-content>
  </div>
  <div
    class="mat-table-container-no-results"
    *ngIf="tableState === TableState.noResult"
  >
    <img
      class="no-results-illu"
      src="dist/tiime-material/assets/illu-no-results.svg"
      draggable="false"
    />
    <span class="no-results-label">Aucun Résultat</span>
  </div>
  <div
    class="mat-table-container-content-placeholder"
    *ngIf="tableState === TableState.contentPlaceholder"
  >
    <div
      class="content-placeholder-row"
      *ngFor="
        let contentPlaceholderRow of contentPlaceholderRows;
        trackBy: trackByIndex
      "
    >
      <div>
        <div class="content-placeholder-cell first"></div>
        <div class="content-placeholder-cell"></div>
      </div>
      <div class="content-placeholder-cell last"></div>
    </div>
  </div>
  <ng-content select="[t2-mat-table]"></ng-content>
</div>
