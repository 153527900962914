import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { EMPTY, from, Observable } from 'rxjs';
import { catchError, filter, map, tap } from 'rxjs/operators';
import { TiimeAuthService } from 'tiime-auth';
import { SnackbarConfig, TiimeSnackbarService } from 'tiime-material';
import { MarkFormAsTouchedIfInvalid } from 'tiime-utils';
import { SignInForm } from './signin-form';
import { AUTH } from '../../constants/auth.constants';
import { POST_SIGN_IN_TARGET_PARAM_NAME } from '../auth-callback/auth-callback.component';
export class SigninComponent {
    constructor(authService, queryParamsMap$, snackbar, windowRef) {
        this.authService = authService;
        this.queryParamsMap$ = queryParamsMap$;
        this.snackbar = snackbar;
        this.windowRef = windowRef;
        this.signInForm = new SignInForm();
        this.tiimePulseUrl = AUTH.tiimePulseWebsiteUrl;
    }
    ngOnInit() {
        this.initPostSignInTargetPath();
    }
    submitSignInForm() {
        this.login();
    }
    clearPostSignInTargetQueryParamFromURL() {
        const queryParams = new URLSearchParams(this.windowRef.location.search);
        queryParams.delete(POST_SIGN_IN_TARGET_PARAM_NAME);
        const queryParamsString = queryParams.toString();
        const urlSearch = queryParamsString ? `?${queryParamsString}` : '';
        this.windowRef.history.replaceState({}, '', `${this.windowRef.location.pathname}${urlSearch}`);
    }
    getLoginObservable() {
        const email = this.signInForm.email.value;
        const password = this.signInForm.password.value;
        const promiseToLogin = this.postSignInTargetPath
            ? this.authService.loginAndRedirect(email, password, `${this.windowRef.location.origin}/auth-callback?${POST_SIGN_IN_TARGET_PARAM_NAME}=${this.postSignInTargetPath}`)
            : this.authService.login(email, password);
        return from(promiseToLogin);
    }
    initPostSignInTargetPath() {
        this.queryParamsMap$
            .pipe(map((queryParams) => queryParams.get(POST_SIGN_IN_TARGET_PARAM_NAME)), filter((postSignInTargetPath) => !!postSignInTargetPath), tap((postSignInTargetPath) => (this.postSignInTargetPath = postSignInTargetPath)), tap(() => this.clearPostSignInTargetQueryParamFromURL()))
            .subscribe();
    }
    login() {
        this.getLoginObservable()
            .pipe(catchError(() => {
            this.loginError();
            return EMPTY;
        }))
            .subscribe();
    }
    loginError() {
        this.snackbar.open('Identifiant ou mot de passe invalide', SnackbarConfig.error);
    }
}
tslib_1.__decorate([
    MarkFormAsTouchedIfInvalid('signInForm'),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", []),
    tslib_1.__metadata("design:returntype", void 0)
], SigninComponent.prototype, "submitSignInForm", null);
