import {
  Component,
  ChangeDetectionStrategy,
  ViewEncapsulation
} from '@angular/core';

import { AUTH } from '../../constants/auth.constants';

@Component({
  selector: 'app-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class AuthLayoutComponent {
  constructor() {}

  navigateToTiimePulse(): void {
    window.open(AUTH.tiimePulseWebsiteUrl, '_blank');
  }
}
