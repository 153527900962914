import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import * as Sentry from '@sentry/browser';

import { environment } from '../../../environments/environment';
import { BusinessUser } from '../models/business-user';

@Injectable({
  providedIn: 'root'
})
export class SentryService {
  constructor() {}

  captureException(error: any): void {
    if (environment.SENTRY_DNS) {
      Sentry.captureException(error.originalError || error);
    } else {
      console.error(error);
    }
  }

  cleanUser(): void {
    if (environment.SENTRY_DNS) {
      Sentry.configureScope(scope => scope.setUser(null));
    }
  }

  init(): void {
    if (environment.SENTRY_DNS) {
      Sentry.init({
        dsn: environment.SENTRY_DNS,
        environment: environment.ENV_NAME,
        release: environment.VERSION,
        beforeSend(event, hint) {
          const error = hint.originalException;
          if (error && error instanceof HttpErrorResponse) {
            return null;
          }
          return event;
        }
      });
    }
  }

  setUserFromBusinessUser(businessUser: BusinessUser): void {
    if (environment.SENTRY_DNS) {
      Sentry.configureScope(scope =>
        scope.setUser({
          id: String(businessUser.id),
          username: businessUser.fullName,
          email: businessUser.email,
          phone: businessUser.phone
        })
      );
    }
  }
}
