import { BusinessUnit } from './business-unit';
import { UserBusinessUnitRole } from './user-business-unit-role';
import { UserBase } from './user-base';
import { User } from './user';
import { Role } from '../enums/role';

export class BusinessUser extends UserBase {
  constructor(
    id?: number,
    firstName?: string,
    lastName?: string,
    email?: string,
    public phone?: string,
    public businessUnits?: BusinessUnit[],
    public hasInternalAccess?: boolean,
    public color?: string,
    public acronym?: string,
    public businessUnitsRole?: UserBusinessUnitRole[],
    public roles?: Role[]
  ) {
    super(id, firstName, lastName, email);
  }

  static fromJson(json: any): BusinessUser {
    return new BusinessUser(
      json.id,
      json.firstname,
      json.lastname,
      json.email,
      json.phone,
      json.business_units
        ? json.business_units.map((businessUnit: any) =>
            BusinessUnit.fromJson(businessUnit)
          )
        : [],
      json.has_internal_access,
      json.color,
      json.acronym,
      json.business_unit_users
        ? json.business_unit_users.map((businessUserBusinessUnitRole: any) =>
            UserBusinessUnitRole.fromJson(businessUserBusinessUnitRole)
          )
        : [],
      json.roles
    );
  }

  static toJson(businessUser: BusinessUser): any {
    return {
      id: businessUser.id,
      firstname: businessUser.firstName,
      lastname: businessUser.lastName,
      email: businessUser.email,
      phone: businessUser.phone,
      business_unit_users: businessUser.businessUnitsRole
        ? businessUser.businessUnitsRole.map(
            (businessUnitRole: UserBusinessUnitRole) =>
              UserBusinessUnitRole.toJson(businessUnitRole)
          )
        : undefined
    };
  }

  static toUser(businessUser: BusinessUser): User {
    const user = new User();
    user.id = businessUser.id;
    user.firstName = businessUser.firstName;
    user.lastName = businessUser.lastName;
    user.email = businessUser.email;
    user.phone = businessUser.phone;
    user.businessUnitsRole = businessUser.businessUnitsRole;
    return user;
  }
}
