<div class="t2-mat-date-list-cell">
  <ng-container
    *ngIf="belongsToCurrentYear; then singleLineDate; else multilineDate"
  ></ng-container>
</div>
<ng-template #singleLineDate>
  <span class="t2-mat-date-list-cell-label">{{ date | date: 'dd MMM' }}</span>
</ng-template>
<ng-template #multilineDate>
  <span class="t2-mat-date-list-cell-label">{{ date | date: 'dd MMM' }}</span>
  <span class="t2-mat-date-list-cell-year">{{ date | date: 'yyyy' }}</span>
</ng-template>
