import { NgModule, ErrorHandler, APP_INITIALIZER, Optional, SkipSelf } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { TiimeAuthService } from 'tiime-auth';

import { AuthModule } from './auth/auth.module';
import { SharedModule } from '../shared/shared.module';
import { ApiInterceptor } from './api.interceptor';
import { NotFoundComponent } from './not-found/not-found.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { SentryService } from './sentry/sentry.service';
import { GlobalErrorHandler } from './global-error-handler';
import { BusinessUserEffects } from './store/business-user/business-user-effect';
import { businessUserReducer } from './store/business-user/business-user-reducer';

export function initializeSentry(sentryService: SentryService) {
  sentryService.init();

  return () => {};
}

export function refreshAuthToken(tiimeAuthService: TiimeAuthService) {
  tiimeAuthService.scheduleRenewal();

  return () => {};
}

@NgModule({
  imports: [
    AuthModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule,
    StoreModule.forRoot({ businessUser: businessUserReducer }),
    EffectsModule.forRoot([BusinessUserEffects])
  ],
  declarations: [NotFoundComponent, UnauthorizedComponent],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeSentry,
      deps: [SentryService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: refreshAuthToken,
      deps: [TiimeAuthService],
      multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
    { provide: ErrorHandler, useClass: GlobalErrorHandler }
  ],
  exports: [BrowserModule]
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule
  ) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only'
      );
    }
  }
}
