<div class="progress-spinner-container" [ngStyle]="containerSize">
  <mat-progress-spinner
    class="background-spinner"
    [diameter]="diameter"
    mode="determinate"
    value="100"
  ></mat-progress-spinner>
  <mat-progress-spinner
    class="progress-spinner"
    [diameter]="diameter"
    [color]="color"
    [mode]="mode"
    [value]="value"
  ></mat-progress-spinner>
</div>
