import { Injectable } from '@angular/core';
import { CanLoad, Router } from '@angular/router';
import { TiimeAuthService } from 'tiime-auth';

import { POST_SIGN_IN_TARGET_PARAM_NAME } from '../auth/auth-callback/auth-callback.component';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanLoad {
  constructor(private authService: TiimeAuthService, private router: Router) {}

  canLoad(): boolean {
    const isAuthenticated = this.authService.isAuthenticated();
    if (!isAuthenticated) {
      const postSignInTargetPath = this.getPostSignInTargetPathFromNavigation();
      this.router.navigate(['signin'], {
        ...(postSignInTargetPath && {
          queryParams: {
            [POST_SIGN_IN_TARGET_PARAM_NAME]: postSignInTargetPath
          }
        })
      });
    }
    return isAuthenticated;
  }

  private getPostSignInTargetPathFromNavigation(): string | null {
    const navigation = this.router.getCurrentNavigation();
    if (navigation) {
      const path = navigation.extractedUrl.toString();
      return path && path !== '/' ? path : null;
    }
    return null;
  }
}
