import * as moment from 'moment';

export type DateInput = moment.MomentInput;

export class DateHelper {
  static add(
    date: DateInput,
    amount: moment.DurationInputArg1,
    unit: moment.unitOfTime.DurationConstructor
  ): moment.Moment {
    return moment(date).add(amount, unit);
  }

  static daysInMonth(date: DateInput): number {
    return moment(date).daysInMonth();
  }

  static diff(
    date1: DateInput,
    date2: DateInput,
    unitOfTime?: moment.unitOfTime.DurationConstructor,
    precise?: boolean
  ): number {
    return moment(date1).diff(date2, unitOfTime, precise);
  }

  static firstDayOfLastMonth(): moment.Moment {
    return moment().subtract(1, 'month').startOf('month');
  }

  static format(date: DateInput, format: string = 'YYYY-MM-DD'): string {
    return moment(date).format(format);
  }

  static lastDayOfLastMonth(): moment.Moment {
    return moment().subtract(1, 'month').endOf('month');
  }

  static isBefore(
    date1: DateInput,
    date2: DateInput,
    granularity?: moment.unitOfTime.StartOf
  ): boolean {
    return moment(date1).isBefore(date2, granularity);
  }

  static isMoment(date: any): boolean {
    return moment.isMoment(date);
  }

  static isSame(
    date1: DateInput,
    date2: DateInput,
    granularity?: moment.unitOfTime.StartOf
  ): boolean {
    return moment(date1).isSame(date2, granularity);
  }

  static isSameOrAfter(
    date1: DateInput,
    date2: DateInput,
    granularity?: moment.unitOfTime.StartOf
  ): boolean {
    return moment(date1).isSameOrAfter(date2, granularity);
  }

  static isSameOrBefore(
    date1: DateInput,
    date2: DateInput,
    granularity?: moment.unitOfTime.StartOf
  ): boolean {
    return moment(date1).isSameOrBefore(date2, granularity);
  }

  static toDate(
    date: DateInput,
    format?: moment.MomentFormatSpecification
  ): Date {
    return moment(date, format).toDate();
  }

  static updateLocale(
    language: string,
    localeSpec: moment.LocaleSpecification
  ): void {
    moment.updateLocale(language, localeSpec);
  }
}
