import { FormGroup, FormControl, Validators } from '@angular/forms';
import { RegexUtils } from 'tiime-utils';

export class ChangePasswordForm extends FormGroup {
  get email(): FormControl {
    return this.get('email') as FormControl;
  }
  get emailErrorMessage(): string {
    return (
      this.email.touched &&
      (this.email.hasError('required')
        ? 'Email requis'
        : this.email.hasError('pattern')
        ? 'Email invalide'
        : null)
    );
  }

  constructor() {
    super({
      email: new FormControl(
        '',
        Validators.compose([
          Validators.pattern(RegexUtils.mailRegex),
          Validators.required
        ])
      )
    });
  }
}
